//Assessment Templates
import React, { useState } from 'react';

import { CheckButton } from '../Components';

//import { StyleSheet, css } from 'aphrodite';
import { Spinner } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';


const DeleteTemplate = ({ template, templates, dbFunctions, updateTemplates, toast }) => {
    const [deleting, setDeleting] = useState(false);
  
    const doDelete = async () => {
      setDeleting(true);
  
      //template._id
  
      //Remove template from list
      const doc = {_id: 'templateList', templates: templates.filter(t=>t._id!==template._id)};
      const tLUpdate = await dbFunctions.dbUpdate({db: 'admin', doc});
      
      if(tLUpdate) {
        //Delete template doc
  
        var response = await dbFunctions.dbDelete({db: 'admin', id: template._id})
  
        updateTemplates();
      }
  
      setDeleting(false);
  
      toast(tLUpdate && response?'Template deleted!':'Template delete failed');
    }
  
    return (
      deleting?<Spinner as="span" animation="border" />:<CheckButton onClick={doDelete} check="Permanently Delete?" />
    )
  }

export default DeleteTemplate;