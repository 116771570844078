import React, { useState } from 'react';
import { Modal, Button, ButtonGroup } from 'react-bootstrap';
import { FAIcon } from '../Components';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

const dbserver = process.env.REACT_APP_DB_SERVER;



const Attachments = ({ title, attachments = [], siteid, assid, dbFunctions, className, style }) => {
  const [show, setShow] = useState(false);

  //const [message, setMessage] = useState('');

  const showModal = s => setShow(s === true || s === false ? s : !show);

  const attcount = attachments.length;

  const openAttachment = (docid, attid) => async () => {

    //await dbFunctions.dbUpdateCookie();
    window.open(`${dbserver}/${siteid}/${docid}/${attid}`, '_blank');
  }

  const attlink = (docid, attid, att) => {

    const src = `${dbserver}/${siteid}/${docid}/${attid}`;

    //console.log("src: ", src);

    if(att.content_type.startsWith('image/')) return (
      <img src={src} title={attid} style={{ maxWidth: '100%', maxHeight: '5em' }} alt={`ID: ${attid}`} />
    )

    if(att.content_type === 'application/pdf') return (
      <Document file={src} onLoadError={console.error}>
        <Page pageNumber={1} width={200} />
      </Document>
    )
      
    return attid;
  }

  return (
    <ButtonGroup className={className} style={style}>

      <Button title="Attachments" onClick={showModal} variant="outline-secondary"><FAIcon icon="paperclip" />{attcount}</Button>

      <Modal show={show} size="lg" onHide={showModal}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {attachments.map(att => {

            return (
              <div key={`attf_${att._id}`} style={{ marginBottom: '1em' }}>
                <div>{att.values?.name}</div>
                {Object.keys(att._attachments).map(k =>
                  <div key={`att_${att._id}_${k}`}>
                    <Button title="Open Attachment" onClick={openAttachment(att._id, k)} variant="outline-secondary">{attlink(att._id, k, att._attachments[k])}</Button>
                  </div>
                )}

              </div>
            )
          })}

        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>
    </ButtonGroup>
  )
}

export default Attachments;