import React, { useState, useEffect } from 'react';
//import { StyleSheet, css } from 'aphrodite';
import { Button, ButtonGroup, Modal, Form, Spinner } from 'react-bootstrap';

import { FAIcon } from '../Components';

import { validateEmail } from '../Utils';

const EmailUser = ({ user, className, style, dbFunctions, toast }) => {

  const [show, setShow] = useState(false);
  //const [message, setMessage] = useState('');
  //const [checked, setChecked] = useState(true);
  const [sending, setSending] = useState(false);

  const [note, setNote] = useState('');
  const [cc, setCc] = useState('');
  const [ccinvalid, setCcinvalid] = useState(null);

  const showModal = s => {
    setShow(s === true || s === false ? s : !show);

    setNote('');
  }

  const fs = n => e => n(e.target.value);

  //Validate email
  useEffect(()=>{
    setCcinvalid(cc?validateEmail(cc):'');
  }, [cc])

  const send = type => async () => {
    setSending(type);

    const fields = {
      useremail: user.username,
      cc,
      useractualname: user.actualname || (user.doc).fullname || '',
      note
    }
  
    //const response = await dbFunctions.dbAdminPost({ query: ['trilogy','custominvite'], doc: {email: formValues.users, name: formValues.uname, oneyear: formValues.one, threeyear: formValues.three }, toast });
    const response = await dbFunctions.dbAdminPost({ query: ['trilogy','sendemail'], doc: {fields, type} });

    if(response) {
      if(response.success) showModal(false);

      toast(response.err?`${response.message}: ${response.err.message}`:response.message);
      console.log("email send response: ", response);
    } else {
      toast(`Unknown error sending email`);
    }

    setSending(false);
  }

  const username = `${user.actualname || (user.doc).fullname || ''} [${user.username}]`;

  return (
    <ButtonGroup className={className} style={style}>

      <Button title="Email User…" onClick={showModal} variant="outline-secondary"><FAIcon icon="envelope-o" /></Button>

      <Modal show={show} size="lg" onHide={showModal}>
        <Modal.Header>
          <Modal.Title>Email User: {username}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/*message && <Alert variant='primary'>
            {message}
          </Alert>*/}

          <Form.Group>
            <Form.Label>CC (optional)</Form.Label>
            <Form.Control type="email" value={cc || ''} onChange={fs(setCc)} />
            <Form.Text className="text-muted">{ccinvalid}</Form.Text>
            {/*<input id="pw" type="password" className="form-control" onChange={sv(setPassword)} autoFocus onKeyPress={enterOK} />*/}
          </Form.Group>

          <Form.Group>
            <Form.Label>Email Note (optional)</Form.Label>
            <Form.Control as="textarea" value={note || ''} onChange={fs(setNote)} />
          </Form.Group>

        </Modal.Body>
        <Modal.Footer>
          {/*<Button type="button" variant="danger" onClick={doDelete} disabled={sending}>{sending ? <Spinner as="span" animation="border" size="sm" /> : <FAIcon icon="trash" />} Delete</Button>*/}
          <Button type="button" variant="primary" onClick={send('logindetails')} disabled={sending || (ccinvalid && ccinvalid!==true)}>{sending && sending==='logindetails' ? <Spinner as="span" animation="border" size="sm" /> : <FAIcon icon="envelope-o" />} Send Login Details</Button>
          <Button type="button" variant="secondary" onClick={showModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </ButtonGroup>
  )
}

export default EmailUser;