const emailcontents = [
  {
    id: 'footer',
    title: 'Email footer',
    description: 'Added to the end of all emails sent',
    subject: false,
    fields: [],
    content: ''
  },
  {
    id: 'newsitenewuser',
    title: 'New Site, New User',
    description: 'Email set to a new user of Firesmart when both a new Site has been created for them at the same time as their account. It should briefly introduce Firesmart and include a link to allow the user to set their name/password.',
    fields: [
      { id: 'useremail', description: 'The user\'s email address', type: 'email', testvalue: 'john@mydomain.test' },
      { id: 'useractualname', description: 'The user\'s actual name', testvalue: 'John Smith' },
      { id: 'sitename', description: 'The name of the Site', testvalue: '1 London Road' },
      { id: 'reseturl', description: 'URL to set user\'s password / check actual name', testvalue: 'https://admin.firesmart.co.uk/users/setpassword?email=john@mydomain.test&rk=ck31pujt70000huioa7j82v5i' },
    ],
    content: ''
  },
  {
    id: 'newsiteexistinguser',
    title: 'New Site, Existing User',
    description: 'Email sent to an existing user when a new Site is added to their account.',
    fields: [
      { id: 'useremail', description: 'The user\'s email address', type: 'email', testvalue: 'john@mydomain.test' },
      { id: 'useractualname', description: 'The user\'s actual name', testvalue: 'John Smith' },
      { id: 'sitename', description: 'The name of the Site', testvalue: '1 London Road' },
      { id: 'appurl', description: 'Link to Firesmart app', testvalue: 'https://app.firesmart.co.uk?email=john@mydomain.test' },
    ],
    content: ''
  },
  {
    id: 'userdeleted',
    title: 'User Deleted',
    description: 'Email sent to a user when their account with Firesmart has been deleted (/suspended).',
    fields: [
      { id: 'useremail', description: 'The user\'s email address', type: 'email', testvalue: 'john@mydomain.test' },
      { id: 'useractualname', description: 'The user\'s actual name', testvalue: 'John Smith' }
    ],
    content: ''
  },
  {
    id: 'sitesharednewuser',
    title: 'Site Shared to New User',
    description: 'Email set to a new user of Firesmart when their account has been created at the point that a Site is shared to them. The Site may be an existing Site or it may have been shared to them at the point of creation by a Firesmart user. It should briefly introduce Firesmart and include a link to allow the user to set their name/password.',
    fields: [
      { id: 'useremail', description: 'The user\'s email address', type: 'email', testvalue: 'john@mydomain.test' },
      { id: 'useractualname', description: 'The user\'s actual name', testvalue: 'John Smith' },
      { id: 'reseturl', description: 'URL to set user\'s password / check actual name', testvalue: 'https://admin.firesmart.co.uk/users/setpassword?email=john@mydomain.test&rk=ck31pujt70000huioa7j82v5i' },
      { id: 'sitename', description: 'The name of the Site', testvalue: '1 London Road' },
      { id: 'sharedbyemail', description: 'The email address of the user who gave access to the site', testvalue: 'lucy@mydomain.test' },
      { id: 'sharedbyactualname', description: 'The name of the user who gave access to the site', testvalue: 'Lucy Jones' }
    ],
    content: ''
  },
  {
    id: 'sitesharedexistinguser',
    title: 'Site Shared to Existing User',
    description: 'Email sent to existing Firesmart user when a Site is shared to them.',
    fields: [
      { id: 'useremail', description: 'The user\'s email address', type: 'email', testvalue: 'john@mydomain.test' },
      { id: 'useractualname', description: 'The user\'s actual name', testvalue: 'John Smith' },
      { id: 'sitename', description: 'The name of the Site', testvalue: '1 London Road' },
      { id: 'sharedbyemail', description: 'The email address of the user who gave access to the site', testvalue: 'lucy@mydomain.test' },
      { id: 'sharedbyactualname', description: 'The name of the user who gave access to the site', testvalue: 'Lucy Jones' },
      { id: 'appurl', description: 'Link to Firesmart app', testvalue: 'https://app.firesmart.co.uk?email=john@mydomain.test' }
    ],
    content: ''
  },
  {
    id: 'siteunshared',
    title: 'Site Unshared',
    description: 'Email sent to a user when their access to a site has been removed.',
    fields: [
      { id: 'useremail', description: 'The user\'s email address', type: 'email', testvalue: 'john@mydomain.test' },
      { id: 'useractualname', description: 'The user\'s actual name', testvalue: 'John Smith' },
      { id: 'sitename', description: 'The name of the Site', testvalue: '1 London Road' },
      { id: 'unsharedbyemail', description: 'The email address of the user who removed access to the site', testvalue: 'lucy@mydomain.test' },
      { id: 'unsharedbyactualname', description: 'The name of the user who removed access to the site', testvalue: 'Lucy Jones' }
    ],
    content: ''
  },
  {
    id: 'sitedeleted',
    title: 'Site Deleted',
    description: 'Email sent to a user when a site to which they have access has been deleted.',
    fields: [
      { id: 'useremail', description: 'The user\'s email address', type: 'email', testvalue: 'john@mydomain.test' },
      { id: 'useractualname', description: 'The user\'s actual name', testvalue: 'John Smith' },
      { id: 'sitename', description: 'The name of the Site', testvalue: '1 London Road' },
      { id: 'deletedbyemail', description: 'The email address of the user who deleted the site', testvalue: 'lucy@mydomain.test' },
      { id: 'deletedbyactualname', description: 'The name of the user who deleted the site', testvalue: 'Lucy Jones' }
    ],
    content: ''
  },
  {
    id: 'passwordresetrequest',
    title: 'Password Reset Request',
    description: 'Email sent to a user who has requested a password reset',
    fields: [
      { id: 'useremail', description: 'The user\'s email address', type: 'email', testvalue: 'john@mydomain.test' },
      { id: 'useractualname', description: 'The user\'s actual name', testvalue: 'John Smith' },
      { id: 'reseturl', description: 'URL to set user\'s password / check actual name', testvalue: 'https://admin.firesmart.co.uk/users/setpassword?email=john@mydomain.test&rk=ck31pujt70000huioa7j82v5i' },
    ],
    content: ''
  },
  {
    id: 'passwordsetconfirmation',
    title: 'Password Set Confirmation',
    description: 'Email sent to user when they have set / reset their password',
    fields: [
      { id: 'useremail', description: 'The user\'s email address', type: 'email', testvalue: 'john@mydomain.test' },
      { id: 'useractualname', description: 'The user\'s actual name', testvalue: 'John Smith' },
      { id: 'appurl', description: 'URL to link to Firesmart app', testvalue: 'https://app.firesmart.co.uk?email=john@mydomain.test' }
    ],
    content: ''
  },
  {
    id: 'custominvite',
    title: 'Custom Invite Email',
    description: 'Email to a new user (or new user of the new system) offering them a custom price for an annual licence',
    fields: [
      { id: 'useremail', description: 'The user\'s email address', type: 'email', testvalue: 'john@mydomain.test' },
      { id: 'useractualname', description: 'The user\'s actual name', testvalue: 'John Smith' },
      { id: 'customurl', description: 'URL link to custom buy page ', testvalue: 'https://admin.firesmart.co.uk/buy?token=ck31pujt70000huioa7j82v5i' },
      { id: 'note', description: 'Optional note entered at time of creation of invitation' }
    ],
    content: ''
  },
  {
    id: 'logindetails',
    title: 'Login details update email',
    description: 'Sent via Admin site to inform/reinform a user of their login details',
    fields: [
      { id: 'useremail', description: 'The user\'s email address', type: 'email', testvalue: 'john@mydomain.test' },
      { id: 'useractualname', description: 'The user\'s actual name', testvalue: 'John Smith' },
      { id: 'pwreseturl', description: 'URL to link to password reset screen', testvalue: 'https://admin.firesmart.co.uk/users/resetpassword?email=john@mydomain.test' },
      { id: 'appurl', description: 'URL to link to Firesmart app', testvalue: 'https://app.firesmart.co.uk?email=john@mydomain.test' },
      { id: 'note', description: 'Optional note entered at time of creation of invitation' }
    ],
    content: ''
  },
  {
    id: 'timetoassessreminder',
    title: 'Time to do an assessment',
    description: 'Email to be sent to user(s) who need to complete / update their fire risk assessment',
    fields: [
      { id: 'useremail', description: 'The user\'s email address', type: 'email', testvalue: 'john@mydomain.test' },
      { id: 'useractualname', description: 'The user\'s actual name', testvalue: 'John Smith' },
      { id: 'sitename', description: 'The name of the Site', testvalue: '1 London Road' },
      { id: 'siteid', description: 'The id of the Site', testvalue: 'site-123' },
      { id: 'assessmentname', description: 'The name of the Assessment', testvalue: 'Fire Risk Assessment' },
      { id: 'assessmentid', description: 'The id of the Assessment', testvalue: '1234567890' },
      { id: 'appurl', description: 'URL to link to Firesmart app', testvalue: 'https://app.firesmart.co.uk?email=john@mydomain.test' }
    ],
    content: ''
  },
];

export default emailcontents;