const pricesoptions = [
    {
      id: 'oneyearprice',
      title: '1 year',
      description: 'Buy for one year',
      type: 'number',
      prepend: '£',
      append: ''
    },
    {
      id: 'threeyearprice',
      title: '3 years',
      description: 'Buy for three years',
      type: 'number',
      prepend: '£',
      append: ''
    }
  ];
  
  export default pricesoptions;