import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

const adminserver = process.env.REACT_APP_ADMIN_SERVER;

const PDFHover = ({ site, assid, report, children }) => {

    const file = `${adminserver}/reports/${site}/${assid}/${report}`;
  
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id={`tooltip-${file}`} className="pdftooltip">
            <div>
              <Document file={file} onLoadError={console.log}>
                <Page pageNumber={1} width={200} />
              </Document>
            </div>
          </Tooltip>
        }
      >
        {children}
      </OverlayTrigger>
    )
  }

export { PDFHover }