import React, { useState, useEffect } from 'react';
//import { StyleSheet, css } from 'aphrodite';
import { Button, Table, ButtonGroup, Modal, Form, Spinner, Alert, InputGroup, Dropdown } from 'react-bootstrap';

//import { ukdate, dbFetch, dbAdminPost, validateEmail, validateNotBlank } from './Utils';
import { Searcher, FAIcon } from '../Components';

import { Paginator } from '../Library';

import EmailUser from './emailuser';
import EditUser from './edituser';

/**
 * Tab - list of all Users in database
 */
const Users = ({ users=[], updateUsers, dbFunctions, usersUpdating, toast }) => {
  //const [message, setMessage] = useState('');

  const [userListDefault, setUserListDefault] = useState([]);
  const [usersFiltered, setUsersFiltered] = useState(users);

  const [numUsers, setNumUsers] = useState(20);
  const [page, setPage] = useState(0);


  //Set default list of filtered Users when users updates
  useEffect(()=>{ setUserListDefault((users || []).sort((a,b)=>(a.doc || {}).created < (b.doc || {}).created ? 1: -1) ) }, [users]);

  //useEffect(() => { setUsersFiltered(users) }, [users]);

  useEffect(()=>setPage(0), [usersFiltered]);

  const deleteUser = userid => async (inform=true) => {
    const du = await dbFunctions.dbAdminPost({ query: ['trilogy','deleteuser'], doc: {userid, inform}, toast });

    if(du && du.success) {
      updateUsers();

      console.log("Delete user: ", du);
    }
    return du;
  }

  return (
    <div>
      <h2>Users {users && <span>[{users.length}]</span>}{usersUpdating && <Spinner animation="border" />}</h2>

      <Table striped bordered hover className="stTable">
        <thead className="stickyHead">
          <tr>
            <th colSpan={3} style={{padding: 0}}>
              <Searcher values={userListDefault} onChange={setUsersFiltered} keys={['value', 'actualname']} >
                <Dropdown>
                    <Dropdown.Toggle variant="outline-secondary" title="Users/page">
                      {numUsers || 'All'}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {
                        [10,20,40,100,'All'].map(n=><Dropdown.Item onClick={()=>setNumUsers(n==='All'?null:n)} key={n}>{n}</Dropdown.Item>)
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                </Searcher>
            </th>
          </tr>
          <tr>
            {/*<th>Creator</th>*/}
            <th>Name</th>
            <th>Email</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
            {(usersFiltered
              ?numUsers
                ?usersFiltered?.slice((page*numUsers),numUsers+page*numUsers)
                :usersFiltered
              :[]
            ).map(u =>
              <tr key={u.id}>
                <td>{u.actualname}</td>
                <td key={u.id}><a href={`mailto:${u.actualname?`${u.actualname}<${u.value}>`:u.value}?subject=Firesmart`} target="_blank" rel="noopener noreferrer">{u.value}</a></td>
                <td>
                  <ButtonGroup>
                    <DeleteUser user={u} deleteUser={deleteUser(u.username)} toast={toast} />
                    <UserPassword user={u} dbFunctions={dbFunctions} toast={toast} />
                    <EmailUser user={u} dbFunctions={dbFunctions} toast={toast} />
                    <EditUser user={u} dbFunctions={dbFunctions} toast={toast} updateUsers={updateUsers} />
                    <UserData user={u} />
                  </ButtonGroup>
                </td>
              </tr>
            )}
        </tbody>
      </Table>
      <Paginator page={page} setPage={setPage} numSites={numUsers} siteCount={(usersFiltered || []).length} />

    </div>
  )
}

const UserData = ({ user={}, className, style }) => {
  const [show, setShow] = useState(false);

  const showModal = s => setShow(s===true || s===false?s:!show);

  return (
    <ButtonGroup className={className} style={style}>

      <Button type="button" variant="outline-secondary" onClick={showModal}><FAIcon icon="ellipsis-h" /></Button>

      <Modal show={show} size="lg" onHide={showModal}>
        <Modal.Header>
          <Modal.Title>User: {user.actualname || user.value}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
        </Modal.Body>
        <Modal.Footer>
          {/*<Button type="button" variant="primary" onClick={doAddSite}>{addingSite && <Spinner as="span" animation="border" size="sm" />} Add</Button>*/}
        </Modal.Footer>
      </Modal>
    </ButtonGroup>
  )
}

const DeleteUser = ({ user, className, style, deleteUser, toast }) => {

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');
  const [checked, setChecked] = useState(true);
  const [deleting, setDeleting] = useState(false);

  const showModal = s => setShow(s===true || s===false?s:!show);

  //Add/remove user from checked list
  const switchUser = e => setChecked(!checked)

  const doDelete = async () => {

    setDeleting(true);
    setMessage('');

    const del = await deleteUser(checked);
    
    setDeleting(false);

    if(del && del.success) {
      toast(`User ${username} deleted`);
      setShow(false);
    }
    else {
      setMessage((del || {}).message || 'Unknown error deleting user');
    }
  }

  const username = `${user.actualname || (user.doc).fullname || ''} [${user.username}]`;

  return (
    <ButtonGroup className={className} style={style}>

      <Button title="Delete User…" onClick={showModal} variant="outline-secondary"><FAIcon icon="trash" /></Button>

      <Modal show={show} size="lg" onHide={showModal}>
        <Modal.Header>
          <Modal.Title>Delete User: {username}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message && <Alert variant='primary'>
            {message}
          </Alert>}

          <Form>
            <Form.Group>
              <Form.Label>Notify User of deletion?</Form.Label>
                <Form.Switch id={`dnot_${user.username}`} checked={checked} onChange={switchUser} label={username} />
            </Form.Group>
          </Form>
          
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" variant="secondary" onClick={showModal}>Cancel</Button>
          <Button type="button" variant="danger" onClick={doDelete} disabled={deleting}>{deleting ? <Spinner as="span" animation="border" size="sm" /> : <FAIcon icon="trash" />} Delete</Button>
        </Modal.Footer>
      </Modal>
    </ButtonGroup>
  )
}

const UserPassword = ({ user, dbFunctions, toast }) => {

  const [show, setShow] = useState(false);
  //const [message, setMessage] = useState('');
  const [sendingReset, setSendingReset] = useState(false);
  const [resetting, setResetting] = useState(false);

  const showModal = s => setShow(s===true || s===false?s:!show);

  //Manually set user password
  const setPassword = async e => {

    e.preventDefault();

    //const result = await adminSubmitFile({ query:['sites', 'tguploadreport'], fields: formValues })

    const userPassword = (((e.target || {}).elements || {}).userPassword || {}).value;

    if(!userPassword) {
      toast('No password value entered');
      return;
    }

    setResetting(true);

    const response = await dbFunctions.dbAdminPost({ query: ['trilogy','setUserPassword'], doc: {email: user.username, userPassword }, toast });

    setResetting(false);

    console.log("response: ", response);

    if(!response) toast("Failed to set password (general error)");
    else if(response && !response.success) toast("Failed to set password: "+response.message);
    else {
      toast(response.message);
      setShow(false);
    }
  }

  //Send email to user allowing them to reset their password
  const sendReset = async () => {

    setSendingReset(true);

    const sr = await dbFunctions.dbAdminPost({ query: ['trilogy','sendResetEmail'], doc: {email: user.username}, toast });

    setSendingReset(false);

    if(sr) {
      toast('Reset email sent');
      showModal(false);
    }

  }

  const username = `${user.actualname || (user.doc).fullname || ''} [${user.username}]`;

  return (
    <ButtonGroup>

      <Button title="Password" onClick={showModal} variant="outline-secondary"><FAIcon icon="key" /></Button>

      <Modal show={show} size="lg" onHide={showModal}>
        <Modal.Header>
          <Modal.Title>Reset user password: {username}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/*message && <Alert variant='primary'>
            {message}
          </Alert>*/}

          <div style={{marginBottom: '1em'}}>
            <Button type="button" onClick={sendReset}>{sendingReset && <Spinner as="span" animation="border" size="sm" />} Send Reset Email</Button>
          </div>

          <p>Ideally, trigger a password reset email by clicking "send reset email". If the user is having trouble with this for some reason, their password can be manually set here.</p>

          <Form onSubmit={setPassword}>
            
              <label>Manually Reset Password</label>
              
              <InputGroup>
                <Form.Control as="input" name="userPassword" />
                  <Button type="submit" variant="danger" disabled={resetting}>{resetting ? <Spinner as="span" animation="border" size="sm" /> : <FAIcon icon="key" />} Set Password</Button>
              </InputGroup>

          </Form>
          
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" variant="secondary" onClick={showModal}>OK</Button>
        </Modal.Footer>
      </Modal>
    </ButtonGroup>
  )

}


export default Users;