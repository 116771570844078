import React, { useState, useEffect } from 'react';
//import { StyleSheet, css } from 'aphrodite';
import { Tabs, Tab, Form, Button, Table, Row, Col, InputGroup, Spinner } from 'react-bootstrap';

//import './App.css';
//import 'bootstrap/dist/css/bootstrap.min.css';
//import 'font-awesome/css/font-awesome.min.css';

import { getCaretPosition } from '../Utils';

//import { ukdate, dbFetch, dbAdminPost, validateEmail, validateNotBlank } from './Utils';
//import { TopMessage, Searcher, FAIcon } from './Components';

import emailcontents from './emailcontents';

/**
 * Display / manipulate email settings
 * @param {*} param0 
 */
const SettingsEmail = ({ settings, update, dbFunctions }) => {

  const [emailData, setEmailData] = useState(emailcontents);

  //Values of contents/subject of each email option *if* they have been changed by the user
  const [contents, setContents] = useState({});
  const [subject, setSubject] = useState({});

  //Values of Test fields
  const [testValues, setTestValues] = useState({});
  const [testEmail, setTestEmail] = useState('info@firesmart.co.uk');

  //True if sending of a test email is in progress
  const [sending, setSending] = useState(false);

  //Carat position within textarea/subject for insertion of fields
  const [cursorpos, setCursorpos] = useState({});
  const [subjectcursorpos, setSubjectcursorpos] = useState({});


  const [inSubject, setInSubject] = useState(false);
  //const [] //Subject/Content

  useEffect(()=>{ 

    /*if(settings)*/ setEmailData(
      emailcontents.map(e=>({
        ...e, 
        ...((settings || []).find(se=>se.id===e.id) || {})
      }))
    )

  }, [settings])

  const updateSubject = id => e => {
    setSubject({ ...subject, [id]: e.target.value });
    updateCursorPos(id, true)(e);
  }

  const updateContents = id => e => {
    setContents({ ...contents, [id]: e.target.value });
    updateCursorPos(id)(e);
  }

  const setTestVal = (id, field) => e => {
    const value = (typeof e === 'object' && e.target) ? e.target.value : e;

    setTestValues({ ...testValues, [id]: { ...testValues.id, [field]: value } });
  }

  //Update cursorpos - position / range of cursor in textarea
  const updateCursorPos = (id, subject = false) => e => {
    //console.log("updateCursorPos: ", id, e, subject);

    const cp = getCaretPosition(e.target);
    subject
      ? setSubjectcursorpos({ ...subjectcursorpos, [id]: cp })
      : setCursorpos({ ...cursorpos, [id]: cp });
    setInSubject(subject); //true = in Subject
  }

  const save = id => async () => {

    const ok = await update(emailData.map(ec => ({
      id: ec.id,
      subject: (id === ec.id && subject[ec.id]) || ec.subject,
      content: (id === ec.id && contents[ec.id]) || ec.content
    })));

    if (ok) {
      const c = { ...contents };
      delete c[id];
      setContents(c);

      const s = { ...subject };
      delete s[id];
      setSubject(s);
    }
  }

  //const sfv = f => e => f(e.target.value);

  const insertField = (id, field) => e => {
    //setContents(`${contents}{{${id}}}`);

    //console.log("inSubject: ", inSubject);

    const text = inSubject
      ? subject[id] || (emailData.find(ec => ec.id === id) || {}).subject || ''
      : contents[id] || (emailData.find(ec => ec.id === id) || {}).content || '';

    let textout;

    const cpos = inSubject ? subjectcursorpos : cursorpos;

    if (cpos[id]) {
      textout = (cpos[id].start === cpos[id].end)
        ? `${text.slice(0, cpos[id].start)}{{${field}}}${text.slice(cpos[id].start)}`
        : `${text.slice(0, cpos[id].start)}{{${field}}}${text.slice(cpos[id].end)}`
    } else {
      textout = `${text}{{${field}}}`;
    }

    inSubject ? setSubject({ ...subject, [id]: textout }) : setContents({ ...contents, [id]: textout });
  }

  const sendTest = id => async e => {

    //const fields = { ...(((emailcontents.find(t=>t.id===id) || {}).fields).map(f=>({})), ...(testValues.id || {}) };

    const template = emailData.find(t => t.id === id) || {};

    const fields = (template.fields || []).reduce((obj, f) => {
      obj[f.id] = typeof (testValues[id] || {})[f.id] === 'undefined' ? f.testvalue : testValues[id][f.id];

      return obj;
    }, {});

    const doc = {
      template: id,
      email: testEmail,
      fields,
      subject: subject[id] || template.subject,
      content: contents[id] || template.content
    }

    setSending({ id, sending: true });

    const em = await dbFunctions.dbAdminPost({ query: ['test', 'emailsettings'], doc });

    //const emresp = em && em.json && (await em.json() || {});

    setSending({ id, sending: false, sent: em.success });

  }

  return (
    <div>
      <div className="tab-body">
        Email contents for automated functions
      </div>
      <Tabs defaultActiveKey={emailData && emailData.length && emailData[0].id} id="settingstabs">
        {emailData.map(t =>
          <Tab key={t.id} eventKey={t.id} title={t.title}>
            <Form style={{ padding: '1em' }}>
              <div className="small">[{t.id}]</div>
              <div>{t.description}</div>

              {/*<Form.Label as="span">Fields</Form.Label>*/}
              <Table striped bordered hover>
                <thead>
                  <tr>
                    {/*<th>Creator</th>*/}
                    <th>Field</th>
                    {/*<th></th>*/}
                    <th>Description</th>
                    <th>Test Value</th>
                  </tr>
                </thead>
                <tbody>
                  {(t.fields || []).map(f =>
                    <tr key={f.id}>
                      <td><Button type="button" variant="link" onClick={insertField(t.id, f.id)}>{`{{`}{f.id}{`}}`}</Button></td>
                      {/*<td><Button type="button" variant="secondary" onClick={insertField(f.id)}>Insert</Button></td>*/}
                      <td>{f.description}</td>
                      <td><Form.Control onChange={setTestVal(t.id, f.id)} defaultValue={f.testvalue} /></td>
                    </tr>
                  )}
                  <tr>
                    <td></td>
                    <td colSpan="2">
                      <Form.Group as={Row}>
                        <Form.Label column sm="2">Test to: </Form.Label>
                        <Col sm="10">
                          <InputGroup>
                            <Form.Control type="email" placeholder="Email" value={testEmail} onChange={e => setTestEmail(e.target.value)} />
                              <Button type="button" variant="outline-secondary" onClick={sendTest(t.id)}>{sending && sending.id === t.id && sending.sending && <Spinner as="span" animation="border" size="sm" />} Test</Button>
                          </InputGroup>
                        </Col>
                      </Form.Group>
                    </td>
                  </tr>
                </tbody>
              </Table>

              {t.subject !== false &&
                <Form.Group>
                  <Form.Label>Subject</Form.Label>
                  <Form.Control value={subject[t.id] || t.subject || ''} onChange={updateSubject(t.id)} onClick={updateCursorPos(t.id, true)} onKeyUp={updateCursorPos(t.id, true)} />
                </Form.Group>}

              <Form.Group>
                <Form.Label>Content</Form.Label>
                <Form.Control as="textarea" value={contents[t.id] || t.content || ''} rows={20} onChange={updateContents(t.id)} onClick={updateCursorPos(t.id)} onKeyUp={updateCursorPos(t.id)} />
              </Form.Group>

              {(contents[t.id] || subject[t.id]) && <Button style={{position: "fixed", right: "0.75em", bottom: "1em"}} type="button" variant="primary" onClick={save(t.id)}>Save</Button>}
              
            </Form>

          </Tab>
        )}
      </Tabs>
    </div>
  )
}

export default SettingsEmail;