//Assessment Templates
import React from 'react';

import { FAIcon } from '../Components';

//import { StyleSheet, css } from 'aphrodite';
import { Button, Table, ButtonGroup } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';

import AddTemplate from './AddTemplate';
import DeleteTemplate from './DeleteTemplate';


const Templates = ({ templates, updateTemplates, dbFunctions, toast }) => {

  //Set default template
  const setDefault = id => async e => {

    const newdoc = {
      _id: 'templateList',
      templates: templates.map(t=>({...t, default: t._id===id}))
    };

    const tLUpdate = await dbFunctions.dbUpdate({db: 'admin', doc: newdoc});

    toast(tLUpdate?`Default Template Set`:`Error setting template!`);

    if(tLUpdate) updateTemplates();
  }

  const downloadTemplate = templateid => async () => {
    const filename = `${templateid}.fsm`; //`${templateid}.json`;

    const templ = await dbFunctions.dbFetch({ query: ['admin', templateid] });

    if(!(templ?.template)) {
      toast("Failed to get template");
      return;
    }

    let link=document.createElement('a');
    link.setAttribute('href', URL.createObjectURL( new Blob([JSON.stringify(templ?.template)], {type: 'text/json'}) ) );
    link.setAttribute('download',filename);
    link.click();
  }

  return (
    <div>
      <h2>Templates {templates && <span>[{templates.length}]</span>}</h2>

      <AddTemplate templates={templates} updateTemplates={updateTemplates} dbFunctions={dbFunctions} toast={toast} />

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Date</th>
            <th>Customer</th>
            <th>Notes</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {templates.sort((a,b)=>a.when>b.when?-1:1).map(t=>
            <tr key={t._id} className="frow" style={t.default?{backgroundColor: 'lightgreen'}:{}}>
              <td>
                <div>{t.name} {t.default?'[DEFAULT]':''}</div>
                {t.description && <div>{t.description}</div>}
                <div className="vsmall">{t._id}</div>
              </td>
              <td>{new Date(t.when).toString().substring(0,24)}</td>
              <td>{t.customer}</td>
              <td>{t.notes}</td>
              <td>
                <ButtonGroup>
                  <Button onClick={downloadTemplate(t._id)} title="Download Template" variant="outline-secondary"><FAIcon icon="floppy-o" /></Button>
                  <DeleteTemplate template={t} templates={templates} dbFunctions={dbFunctions} toast={toast} updateTemplates={updateTemplates} />
                  <Button onClick={setDefault(t._id)} title={t.default?'Default':'Set Default'} disabled={t.default} variant="outline-secondary"><FAIcon icon={t.default?"certificate":"circle-o"} /></Button>
                </ButtonGroup>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  )
}

export default Templates;