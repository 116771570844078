//var follow = require('follow');
const cuid = require('cuid');

const dbserver = process.env.REACT_APP_DB_SERVER;
const adminserver = process.env.REACT_APP_ADMIN_SERVER;


/*let subsite = 'alpha';

if(window.location.hostname.includes('alpha')) subsite='alpha';
else if(window.location.hostname.includes('beta')) subsite='beta';*/
//else subsite='';

const lz = num => num < 10 ? `0${num}` : num;
const ukdate = ms => { if (!ms) return ''; const d = new Date(ms); return `${lz(d.getDate())}/${lz(d.getMonth() + 1)}/${d.getFullYear().toString().substring(2)}`; }

const dtime = ms => { if (!ms) return ''; const d = new Date(ms); return `${lz(d.getHours())}:${lz(d.getMinutes())}`; }

/**
 * Fetch from / perform function in admin
 * @param {string} model eg 'sites'
 * @param {string} section eg 'create'
 * @param {object} fields
 */
const dbFetch = async ({ query, params={}, method, includedocs=true, username, password, logout }) => {
  let response, json;

  if(!query) throw new Error("dbFetch error: no query passed");

  //const q = typeof query==="object"?query._id:query;

  //console.log("dbFetch request: ", query, params);

  //const url = `https://${['db', subsite].join('.')}.firesmart.co.uk/${[].concat(query).join('/')}?include_docs=true`;

  const url = `${dbserver}/${[].concat(query).join('/')}${includedocs?'?include_docs=true':''}`;

  const req = {
    mode: "cors",
    cache: "no-cache",
    method: method || "get",
    credentials: 'include',
    ...params,
    ...(username?{headers: {
      //'X-CouchDB-WWW-Authenticate': 'Cookie',
      'content-type': 'application/json',
      //'authorization': `Basic ${btoa(`${username}:${password}`)}`
    }}:{})
  };

  //console.log("dbFetch URL / req: ", url, req);

  try {
    response = await fetch(url, req);
  }
  catch (err) {
    //throw new Error(err);
    console.log("dbFetch error: ", err, query, params);
    return false;
  }

  //console.log("dbFetch response: ",  url, response);

  if (response.status === 403 && logout) logout();

  if(!response || !response.ok) return false;

  try {
    json = await response.json();
  }
  catch (err) {
    console.log("Error: ", err, response);
    throw new Error('Unexpected response from server; ', response);
  }

  return json;
}

const dbPut = async ({ db, doc, username, password, logout }) => {
  let response;

  const docid = doc._id || cuid();

  const url = `${dbserver}/${db}/${docid}`;

  try {

    const req = {
      mode: "cors",
      cache: "no-cache",
      method: "put",
      credentials: 'include',
      ...(username?{headers: {
        'content-type': 'application/json',
        //'authorization': `Basic ${btoa(`${username}:${password}`)}`
      }}:{}),
      body: JSON.stringify(doc)
    };

    response = await fetch(url,
      req
    )
  }
  catch (err) {
    console.log("err: ", err);
    return false;
  }

  if (response.status === 403 && logout) logout();

  return response.ok;
}

/**
 * 
 * @param {*} db 
 * @param {*} doc 
 * @param {*} idprefix 
 */
const dbAdminPost = async ({ query, username, password, logout, doc, method='post', toast, raw }) => {
  var response;

  //console.log("dbAdminPost: ", query, username, password, doc, method='post', toast);

  const url = `${adminserver}/${[].concat(query).join('/')}`;

  //console.log("dbAdminPost url: ", url);

  try {

    const call = {
      mode: "cors",
      cache: "no-cache",
      method,
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({...doc, username, password})
    };

    response = await fetch( url, call );

    if (response.status === 403 && logout) logout();

    if(raw) return response;

    return response && response.json && response.json();

  }
  catch (err) {
    console.log("err: ", err);
    if(toast) toast(`Error: ${err}`);
    return false;
  }
}

/**
 * Submit form to admin
 */
const dbAdminSubmitFile = async ({ query, username, password, logout, formData=new FormData() }) => {
  const url = `${adminserver}/${[].concat(query).join('/')}`;

  formData.set('username', username);
  formData.set('password', password);

  /*
  console.log("dbAdminSubmitFile: ", query, username, password, );
  for (var pair of formData.entries()) {
    console.log(pair[0]+ ', ' + pair[1]); 
  }
  */

  try {
      var response = await fetch(url,
        {
          method: 'post',
          body: formData
        }
      )

      if (response.status === 403 && logout) logout();

      return response && response.json && response.json();

  } catch (err) {
    console.log("err: ", err);
    return false;
  }

}

/**
 * Update an existing document (repeat until success)
 * @param {*} db 
 * @param {*} doc 
 */
const dbUpdate = async ({ db, doc, username, password, logout }) => {

  if(!(doc && doc._id)) {
    throw new Error('Must specify doc _id');
  }

  //const url = `https://${['db', subsite].join('.')}.firesmart.co.uk/${db}/${doc._id}`;

  var ok, attempts=10;

  while(!ok && attempts>0) {
    attempts--;
    var olddoc = await dbFetch({query: [db, doc._id], username, password, logout});

    var newdoc = olddoc?{...doc, _rev: olddoc._rev}:doc;

    //console.log("dbUpdate: ", db, newdoc);

    ok = await dbPut({db, doc: newdoc, username, password});
  }

  return (
    ok
      ?newdoc
      :false
  );

  /*
  try {
    response = await fetch(url,
      {
        mode: "cors",
        cache: "no-cache",
        method: "get",
        credentials: 'include'
      }
    )
  }
  catch (err) {
    console.log("err: ", err);
  }*/
}

/**
 * 
 * @param {*} param0 
 */
const dbDelete = ({ db, id, username, password, logout }) => {

  return dbUpdate({ db, doc: {_id: id, deleted: true}, username, password, logout });
}

const dbUpdateCookie = async ({ username, password, logout }) => {

  try {
    var response = fetch(`${dbserver}/_session`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'content-type': 'application/json',
        //authorization: `Basic ${btoa(`${username}:${password}`)}`
      },
      body: JSON.stringify({ name: username, password })
    })
  }
  catch (err) {
    //throw new Error(err);
    console.log("dbUpdateCookie error: ", err);
    return false;
  }

  if (response.status === 403 && logout) logout();

  return true;
}

const dbFollow = ({username, password, f}) => {

  /*console.log("Follow: ", follow);

  follow(`${dbserver}/_db_updates`, (error, change) => {
    if(!error) {
      console.log("Change " + change.seq + " has " + Object.keys(change.doc).length + " fields");
    }
  })*/
}

/**
 * All the database functions in one easy payment
 * @param {*} param0 
 */
const dbFunctions = ({ username, password, logout }) => {

  return {
    dbFetch: params => dbFetch({ username, password, logout, ...params}),
    dbPut: params => dbPut({ username, password, logout, ...params}),
    dbAdminPost: params => dbAdminPost({ username, password, logout, ...params}),
    dbAdminSubmitFile: params => dbAdminSubmitFile({ username, password, logout, ...params}),
    dbUpdate: params => dbUpdate({ username, password, logout, ...params}),
    dbDelete: params => dbDelete({ username, password, logout, ...params}),
    dbUpdateCookie: params => dbUpdateCookie({ username, password, logout, ...params}),
    dbFollow: params => dbFollow({ username, password, ...params})
  }
}

const validateEmail = (email) => {
  const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return re.test(String(email).toLowerCase()) || "Invalid Email Address";
}

const validateNotBlank = (val) => String(val).length>0 || "Field cannot be left blank";

const readFileAsync = (file, json=true) => 
	new Promise((resolve, reject) => {
		let reader = new FileReader();

		reader.onload = e => {
      if(json) return resolve(JSON.parse(e.target.result));
      else return resolve(e.target.result);
		};

		reader.onerror = reject;

		reader.readAsText(file);
})

const readBinaryFileAsync = (file, json=true) => 
	new Promise((resolve, reject) => {
		let reader = new FileReader();

		reader.onload = e => {
      return resolve(e.target.result);
		};

		reader.onerror = reject;

		reader.readAsDataURL(file);
})

const humanFileSize = size => {
  const i = size ? Math.floor(Math.log(size) / Math.log(1024)) : 0;
  return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
};

const getCaretPosition = ctrl => {
  // IE < 9 Support 
  if (document.selection) {
      ctrl.focus();
      var range = document.selection.createRange();
      var rangelen = range.text.length;
      range.moveStart('character', -ctrl.value.length);
      var start = range.text.length - rangelen;
      return {
          'start': start,
          'end': start + rangelen
      };
  } // IE >=9 and other browsers
  else if (ctrl.selectionStart || ctrl.selectionStart === '0' || ctrl.selectionStart === 0) {
      return {
          'start': ctrl.selectionStart,
          'end': ctrl.selectionEnd
      };
  } else {
      return {
          'start': 0,
          'end': 0
      };
  }
}

/**
 * Copy the given string to clipboard
 * @param {*} str 
 */
const copyToClipboard = (str, toast) => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);

  if(toast) {
    toast(`Copied to clipboard: ${str}`);
  }
};

const isArray = obj => !!obj && obj.constructor === Array;


export {
  ukdate,
  dtime,
  dbFetch,
  dbPut,
  dbAdminPost,
  dbAdminSubmitFile,
  dbUpdate,
  dbFunctions,
  validateEmail,
  validateNotBlank,
  readFileAsync,
  readBinaryFileAsync,
  humanFileSize,
  getCaretPosition,
  copyToClipboard,
  isArray
}