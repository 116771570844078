import React, { useState, useRef, useEffect } from 'react';

import { Button, ButtonGroup, Spinner, InputGroup, Modal, Table, Form, Alert } from 'react-bootstrap';
//import { Document, Page } from 'react-pdf';

import { ukdate, dtime } from '../Utils'; //validateNotBlank
import { FAIcon, CheckButton, Icon } from '../Components';

import Licence from './Licence';
import Attachments from './Attachments';
import Reports from './Reports';
import { ArchiveModal } from './Archive';
import { UpgradeAssessmentModal } from './UpgradeAssessment';
import { PDFHover } from './PDFHover';

const adminserver = process.env.REACT_APP_ADMIN_SERVER;

/**
 * Add existing PDF report to Assessment
 * @param {*} param0 
 */
 const UploadReport = ({ siteid, updateSite, dbFunctions, assessmentid, className, style, toast }) => {

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');

  const [name, setName] = useState('');
  const [modified, setModified] = useState('');
  const [includeDate, setIncludeDate] = useState(false);
  //const [report, setReport] = useState();
  const [adding, setAdding] = useState(false);

  const fl = useRef(null);

  const showModal = s => setShow(s === true || s === false ? s : !show);

  const clear = () => {
    fl.current = null;
    setName('');
  }

  const clearAndShow = () => {
    clear();
    showModal(true);
    setModified('');
    setIncludeDate(false);
  }

  const submit = async e => {

    e.preventDefault();

    //const result = await adminSubmitFile({ query:['sites', 'tguploadreport'], fields: formValues })

    var formData = new FormData(e.target);

    formData.set('assessmentid', assessmentid);
    formData.set('siteid', siteid);

    setAdding(true);

    const response = await dbFunctions.dbAdminSubmitFile({ query: ['sites', 'tguploadreport'], formData });

    setAdding(false);

    if (!response) setMessage("Failed to upload report (general error)");
    else if (response && !response.success) setMessage("Error uploading report: " + response.message);
    else {
      toast(response.message);
      updateSite();
      setShow(false);
    }
  }

  const fileSelected = e => {
    if (e.target.files && e.target.files[0] && !name) {
      setName(e.target.files[0].name);
      setModified(new Date(e.target.files[0].lastModified).toISOString().substring(0, 10));
    }
  }

  const switchReportDate = e => setIncludeDate(!includeDate);

  return (
    <ButtonGroup className={className} style={style}>

      <Button title="Upload Report" onClick={clearAndShow} variant="outline-secondary"><Icon>file-pdf-o</Icon>+</Button>

      <Modal show={show} size="lg" onHide={showModal}>
        <Form onSubmit={submit}>
          <Modal.Header closeButton>
            <Modal.Title>Upload Report</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {message && <Alert variant='primary'>
              {message}
            </Alert>}

            <Form.Group>
              <Form.Label>Report Upload</Form.Label>
              <Form.Control as="input" type="file" accept={".pdf"} name="report" ref={fl} onChange={fileSelected} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Report Name</Form.Label>
              <Form.Control as="input" name="reportname" value={name} onChange={e => setName(e.target.value)} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Report Date (optional)</Form.Label>
              <InputGroup>
                <InputGroup.Checkbox name="includedate" checked={includeDate} onChange={switchReportDate} />
                <Form.Control type="date" name="reportdate" value={modified} onChange={e => setModified(e.target.value)} disabled={!includeDate} />
              </InputGroup>
            </Form.Group>


          </Modal.Body>
          <Modal.Footer>
            <Button type="submit">{adding && <Spinner as="span" animation="border" size="sm" />} Upload</Button>
          </Modal.Footer>
        </Form>
      </Modal>

    </ButtonGroup>
  )
}

const AssessmentOptions = ({ title, assessmentid, template, assessment, fields, className, style }) => {

  const [show, setShow] = useState(false);

  //const [message, setMessage] = useState('');

  const showModal = s => setShow(s === true || s === false ? s : !show);

  return (
    <ButtonGroup className={className} style={style}>

      <Button title="Assessment Info" onClick={showModal} variant="outline-secondary"><FAIcon icon="info-circle" /></Button>

      <Modal show={show} size="lg" onHide={showModal}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <ul>
            <li><b>id:</b> {assessmentid}</li>
            <li><b>Template:</b> <ul>
              {template.default?<li><b>Current default template</b></li>:''}
              <li><b>id:</b> {assessment.templateid}</li>
              <li><b>Name:</b> {template.name}</li>
              <li><b>Description:</b> {template.description}</li>
              <li><b>Date:</b> {new Date(template.when).toString().substring(0,24)}</li>
            </ul></li>
          </ul>

          {assessment.templatehistory &&
          <>
            <h5>Template Update History</h5>
            <ul>
              {assessment.templatehistory.map(th=>
                <React.Fragment key={th.templateid}>
                <li>
                  <ul>
                    <li><b>id:</b> {th.templateid}</li>
                    <li><b>Updated:</b> {ukdate(th.when)} {dtime(th.when)}</li>
                    <li><b>Previous Template:</b> {th.previoustemplateid}</li>
                  </ul>
                </li>
                </React.Fragment>
              )}
            </ul>
          </>
          }

          <Table striped bordered hover>
            <tbody>
              {(fields || []).map(f => {

                if (!f) return null;

                const v = f?.values || {};
                if (f.type === "TextField") return <tr key={f._id}><th>{v.name}</th><td>{v.value}</td></tr>
                if (f.type === "DateField") return <tr key={f._id}><th>{v.name}</th><td>{v.value ? ukdate(v.value) : ''}</td></tr>
                return null;
              })}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>
    </ButtonGroup>
  )
}

const Assessment = ({ assessment, site, sitename, templates, updateSite, dbFunctions, toast }) => {

  const [template, setTemplate] = useState({});

  useEffect(()=>{
    if(templates && assessment.templateid) {
      setTemplate(templates.find(t=>t._id===assessment.templateid) || {})
    }
  }, [templates])

  const openReport = (assid, report) => {
    const url = `${adminserver}/reports/${site.id}/${assid}/${report}`;
    window.open(url, '_blank');
  }

  const downloadAssessment = (surveyid) => async e => {
    const ass = await dbFunctions.dbAdminPost({ query: ['trilogy', 'getAssessment'], doc: { siteid: site.id, surveyid } });

    if (!(ass && ass.length)) {
      toast('Failed to download assessment');
      return;
    }

    const sname = site?.site?.values?.name;
    const name = ass[0]?.values?.name;
    const cdate = new Date().toISOString().split('.')[0];

    //console.log("Ass: ", ass);

    let link = document.createElement('a');
    link.setAttribute('href', URL.createObjectURL(new Blob([JSON.stringify(ass)], { type: 'text/json' })));
    link.setAttribute('download', `FRA_${sname}-${name}-${surveyid}-${cdate}.json`);
    link.click();
  }

  const deleteAssessment = surveyid => async () => {
    const del = await dbFunctions.dbAdminPost({ query: ['sites', 'deleteAssessment'], doc: { siteid: site.id, surveyid } });

    console.log("del: ", del);

    if (del) {
      if (del.message) toast(del.message);

      if (del.success) updateSite();
    }

  }

  const a = assessment;

  const av = a.values || {};
  //const ad = a.assessmentData || {};
  const si = a.SiteInfo || {};
  const li = a.licence;
  const att = a.attachments;

  const v = site?.site?.values;

  const reports = a.reports || [];
  //const latestreport = reports.length>0 && reports[reports.length-1];

  //const latestreport = reports.reduce((a,c)=>c && !c.uploaded && c.when && new Date(c.when).getTime()>(new Date(a?.when || 0).getTime())?c:a, false);
  const latestreport = a.latestreport;

  if (!li?._id && av.licence) {

    const n = new Date(a.created_when);
    n.setFullYear(n.getFullYear() + 1);
    const expires = n.getTime();

    var lic = {
      _id: av.licence,
      siteid: site.value,
      user: si.user || site?.site?.user,
      when: a.created_when,
      expires,
      notcreated: true //This isn't yet a record
    }
  }

  const templatehist = a.templatehistory && a.templatehistory.length>0 && a.templatehistory[0];

  return (
    <div>
      <div style={{float: 'right', color: template.default?'green':''}}><FAIcon icon={template.default?"certificate":"circle-o"} title={template.default?'Default Template':`Template date: ${new Date(template.when).toString().substring(0,24)}`} /></div>
      <div>{av.name}</div>
      {/*<div className="small">Last Updated: {ukdate(ad.when)}</div>*/}
      <div className="small">Last Updated: {si.when ? `${ukdate(si.when)} (${dtime(si.when)})` : ''}</div>
      {/*av.licence && <div className="small">Licence: {av.licence}</div>*/}
      {/*li?._id ? <Licence licence={li} toast={toast} dbFunctions={dbFunctions} updateSite={updateSite} /> : <span className="small">Licence: {av.licence}</span>*/}
      {av.licence && <Licence licence={li._id ? li : lic} toast={toast} dbFunctions={dbFunctions} updateSite={updateSite} />}
      {latestreport?.when ? <div><Button variant="link" onClick={() => { openReport(a._id, latestreport.id) }}><span className="small">Latest Report {latestreport.when && ukdate(latestreport.when)}</span></Button></div> : ''}
      {templatehist && <div className="temphist">Template last updated: {ukdate(templatehist.when)} {dtime(templatehist.when)}</div>}
      <div>
        <ButtonGroup>
          {/*<Button variant="outline-secondary" onClick={()=>{setShowInfoModal(a._id)}}><FAIcon icon="info-circle" /></Button>*/}
          <AssessmentOptions assessmentid={a._id} assessment={a} template={template} title={`${v.name}: ${av.name}`} fields={a.assessmentDataFields} />
          {/*<Button variant="outline-secondary" onClick={()=>{}}><FAIcon icon="trash" /></Button>*/}
          <CheckButton title="Delete Assessment" onClick={deleteAssessment(a._id)} check="Delete Assessment?" />
          <Button title="Download Assessment" variant="outline-secondary" onClick={downloadAssessment(a._id)}><FAIcon icon="floppy-o" /></Button>
          <Reports reports={reports} siteid={site.id} assid={a._id} dbFunctions={dbFunctions} updateSite={updateSite} toast={toast} />
          {latestreport &&
            <PDFHover site={site.id} assid={a._id} report={latestreport.id}>
              <Button variant="outline-secondary" onClick={() => { openReport(a._id, latestreport.id) }}><FAIcon icon="file-pdf-o" /></Button>
            </PDFHover>}
          <UploadReport dbFunctions={dbFunctions} siteid={site.id} updateSite={updateSite} assessmentid={a._id} toast={toast} />
          {att?.length > 0 && <Attachments title={`${v.name}: ${av.name}`} attachments={att} dbFunctions={dbFunctions} siteid={site.id} assid={a._id} />}
          <ArchiveModal AssessmentName={av.name} siteid={site.id} assid={a._id} dbFunctions={dbFunctions} updateSite={updateSite} archive={a.archive} toast={toast} />
          {/* <Button title="Upgrade Assessment" variant="outline-secondary" onClick={upgradeAssessment(a._id)}><FAIcon icon="arrow-circle-o-up" /></Button>*/}
          <UpgradeAssessmentModal surveyid={a._id} AssessmentName={av.name} siteid={site.id} sitename={sitename} templates={templates} dbFunctions={dbFunctions} updateSite={updateSite} toast={toast} />
        </ButtonGroup>
      </div>
    </div>
  )
}

export { Assessment }