//Assessment Templates
import React, { useState, useEffect } from 'react';

import { CheckButton, Searcher } from './Components';

//import { StyleSheet, css } from 'aphrodite';
import { Modal, Alert, Form, Button, Spinner, Table } from 'react-bootstrap';

import { readBinaryFileAsync, humanFileSize, copyToClipboard } from './Utils';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';


const shortPath = filename => `/files/${filename}`;

const fullPath = filename => `${process.env.REACT_APP_APP_URL}${shortPath(filename)}`;


const ImgPreview = ({ isImg, filename, fullpathfilename, filenameendpath, toast }) => {
  const [show, setShow] = useState(false);

  const showModal = s => {
    const sh = s === true || s === false ? s : !show;
    setShow(sh);
  }

  if(!isImg) return null;

  return (
    <>
      <button onClick={showModal} className="plain"><img alt={filename} src={fullpathfilename} style={{maxHeight: '2em'}} /></button>

      <Modal show={show} size="lg" onHide={showModal}>
        <Modal.Header>
          <Modal.Title>{filename}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={fullpathfilename} alt={filename} style={{maxWidth: "100%"}} />
          <div style={{marginTop: "1em"}}>
            {filenameendpath} <Button onClick={()=>copyToClipboard(filenameendpath, toast)}>Copy path to clipboard</Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="cancel" variant="success" onClick={showModal}>OK</Button>
        </Modal.Footer>
      </Modal>
    </>
  )

}

//File extensions for files that are probably images
const imgTypes = ['jpg', 'jpeg', 'png', 'gif'];

/**
 * Get list of files from server
 */
  const getFileList = async () => {
  try {
    var filelist = await fetch(`${process.env.REACT_APP_APP_URL}/filelist`);

    var filelistdata = await filelist.json();
  } catch (err) {

  }
  
  return (((filelistdata || {}).success && filelistdata.files) || []).map(f=>{
    
    const extension = f.split('.').pop().toLowerCase();
    const isImg = imgTypes.includes(extension);

    return ({
      filename: f,
      isImg
    })
  });
}

const Files = ({ dbFunctions, toast }) => {

  const [message, setMessage] = useState('');
  const [fileList, setFileList] = useState([]);

  const [fileListFiltered, setFileListFiltered] = useState([]);

  const [show, setShow] = useState(false);

  const [isComplete, setIsComplete] = useState(false);

  const [file, setFile] = useState();
  const [imgFile, setImgFile] = useState(false);
  const [fileSize, setFileSize] = useState(false);
  const [lastUploaded, setLastUploaded] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);

  useEffect(()=>{setFileListFiltered(fileList)},[fileList]); //Set filtered list of files for Searcher initially to all files

  //Show / Hide modal (empty parameter toggles; true/false sets)
  const showModal = s => {
    const sh = s === true || s === false ? s : !show;

    clear();

    setShow(sh);
  }

  /**
   * Update local list of files on server
   */
  const updateFileList = async () => {
    const filelist = await getFileList();
    setFileList(filelist || []);
  }

  /**
   * Initial call to update file list
   */
  useEffect(() => {
    updateFileList();
  }, [])

  /**
   * Delete a from the server
   * @param {*} filename 
   */
  const deleteFile = filename => async e => {

    const formData = new FormData();
    formData.set('filename', filename);

    try {
      var response = await dbFunctions.dbAdminSubmitFile({ query:['trilogy', 'deletefile'], formData });
    } catch (err) {
  
    }

    if(!response) {
      toast("Failed to delete file");
      //setMessage("Failed to upload file");
    }
    else if(response && !response.success) {
      //setMessage("Error uploading file: "+response.message);
      toast(`Error deleting file: ${response.message}`);
    }
    else {
      updateFileList();
      showModal(false);
      toast(`File '${filename}' deleted!`);
    }
  }

  /**
   * Clear everything
   */
  const clear = () => {
    setMessage();
    setFile();
    setImgFile(false);
    setFileSize();
    setIsComplete(false);
    setUploadingFile(false);
    setLastUploaded(false);
  }

  /**
   * Load a file into the browser for inspection / preview (if image)
   * @param {} e 
   */
  const addDoc = async e => {
    clear();
  
    if(e.target.files && e.target.files[0]) {
      //if(this._mounted!==false) this.setState({imloading: true});
  
      const file=e.target.files[0];

      //Does this appear to be an image?
      const extension = file.name.split('.').pop().toLowerCase();
      const isImg = imgTypes.includes(extension);

      try {
        var filein = await readBinaryFileAsync(file);
  
        setFile(filein);
        if(isImg) setImgFile(filein);
        setFileSize(file.size);

        //if(this._mounted!==false) this.setState({notice: name?`${name}: ${json?`${json.length} rows`:''}`:"Incorrect format", file: name?json:null});
        
        //setMessage(`File uploaded${name?': '+name:''} [${json.length} rows]`);
  
        //e.target.value="";
  
        //this.setShow(true);
      }
      catch(err) {
        console.log("File Import Error: ", err);
        
        setMessage(err.message);
  
        return err;
      }
    }
  }

  /**
   * Upload file to server
   * @param {*} e 
   */
  const uploadFile = async e => {
    e.preventDefault();

    //const result = await adminSubmitFile({ query:['sites', 'tguploadreport'], fields: formValues })

    var formData = new FormData(e.target);

    setUploadingFile(true);

    const response = await dbFunctions.dbAdminSubmitFile({ query:['trilogy', 'uploadfile'], formData });

    setUploadingFile(false);

    if(!response) {
      toast("Failed to upload file");
      //setMessage("Failed to upload file");
    }
    else if(response && !response.success) {
      //setMessage("Error uploading file: "+response.message);
      toast(`Error uploading file: ${response.message}`);
    }
    else {
      const filename = formData.get('file').name;

      setLastUploaded(shortPath(filename));
      toast(`File uploaded!`);

      await updateFileList();
    }
  }

  return (
    <div>
      <div className="tab-body">
        <h2>App Files {fileList && <span>[{fileList.length}]</span>}</h2>
        <p className="tabinfo">Upload files here to make them available to the App (even offline).
        <br />Please ensure that images are no larger than they need to be - check resolution and quality settings.
        <br />Consider compressing PDFs before upload: <a href="https://www.ilovepdf.com/compress_pdf" target="_blank" rel="noopener noreferrer">Online PDF optimiser</a>
        </p>
        

        <Button onClick={showModal}>Add File</Button>

      </div>

      <Modal show={show} size="lg" onHide={showModal}>
        <Form onSubmit={uploadFile}>
          <Modal.Header>
            <Modal.Title>Add File</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {message && <Alert variant='primary'>{message}</Alert>}

            <Form.Group>
              <Form.Label>File Upload</Form.Label>
              <Form.Control as="input" type="file" onChange={addDoc} name="file" />
            </Form.Group>

            {fileSize && <div>{humanFileSize(fileSize)}</div>}

            {imgFile && <img className="prevImg" src={imgFile} alt="Preview" />}

            {lastUploaded && 
              <div style={{marginTop: "1em"}}>
                {lastUploaded} <Button onClick={()=>copyToClipboard(lastUploaded, toast)}>Copy path to clipboard</Button>
              </div>}

          </Modal.Body>
          <Modal.Footer>
            {isComplete && <Button type="button" variant="success" onClick={showModal}>OK</Button>}
            {!isComplete && <Button type="button" variant="secondary" onClick={showModal}>Cancel</Button>}
            {!isComplete && <Button type="submit" variant="primary" disabled={!file || uploadingFile}>{uploadingFile?<Spinner animation="border" size="sm" />:''} Upload </Button>}
          </Modal.Footer>
        </Form>
      </Modal>

      <Table striped bordered hover className="stTable">
        <thead>
          <tr>
            <th colSpan={3} style={{padding: 0}}>
              <Searcher
                values={fileList}
                onChange={setFileListFiltered}
                keys={['filename']}
              />
            </th>
          </tr>
          <tr>
            <th>File</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {(fileListFiltered || []).map(f=>{

            //const fullpathfilename = `${process.env.REACT_APP_APP_URL}/files/${f.filename}`;
            //const filenameendpath = `/files/${f.filename}`

            const fullpathfilename = fullPath(f.filename);
            const filenameendpath = shortPath(f.filename);

            return (
              <tr key={f.filename}>
                <td><button className="plain" onClick={()=>copyToClipboard(filenameendpath, toast)}>{f.filename}</button></td>
                <td><ImgPreview isImg={f.isImg} filename={f.filename} fullpathfilename={fullpathfilename} filenameendpath={filenameendpath} toast={toast} /></td>
                {/*<td>{f.isImg && <img alt={f.filename} src={fullpathfilename} style={{maxHeight: '2em'}} />}</td>*/}
                <td><CheckButton onClick={deleteFile(f.filename)} check="Permanently Delete?" /></td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  )
}

export {
  Files
}