const orderReport = (vals=[], orderBy) => {

    //const direction = orderBy?.fieldno===fieldno && orderBy?.direction ? -(orderBy.direction) : 1;

    if(!(orderBy.direction)) return vals;

    const fieldno = orderBy.fieldno, direction = orderBy.direction;

    //Check for date field
    var datematch = null;
    vals.some((fl,i)=>{

      const f = Object.values(fl);

      if(f[fieldno]) {
        if(String(f[fieldno]).trim().match(/^\d\d?\/\d\d?\/\d\d(\d\d)?$/g)) datematch=true;
        else {
          datematch=false;
          return true;
        }
      }
      return false;
    })

    //setOrderBy({fieldno, direction});

    const ro = datematch
      ?vals.slice().sort( (ao,bo)=>{ //Order dates
        const a = Object.values(ao), b=Object.values(bo);

        //if(!(a && a[fieldno])) return 1*direction;
        //if(!(b && b[fieldno])) return -1*direction;
        if(a[fieldno]==='') return 1;
        if(b[fieldno]==='') return -1;

        const ad = String(a[fieldno]).split('/');
        const bd = String(b[fieldno]).split('/');

        if(ad[2]!==bd[2]) return (ad[2]<bd[2]?-1:1)*direction;
        if(ad[1]!==bd[1]) return (ad[1]<bd[1]?-1:1)*direction;
        if(ad[0]!==bd[0]) return (ad[0]<bd[0]?-1:1)*direction;
        return 0;
      })
      :vals.slice().sort( (ao,bo)=>{ //Alphanumerically order everything else
        const a = Object.values(ao), b=Object.values(bo);

        if(a[fieldno]==='') return 1;
        if(b[fieldno]==='') return -1;
        
        //return ( (a[fieldno]<b[fieldno]?-1:1)*direction );

        const lc = String(a[fieldno]).localeCompare(String(b[fieldno]), undefined, {
          numeric: true,
          sensitivity: 'base'
        }) * direction;

        return lc;

      });

    return ro;
  }

  export default orderReport;