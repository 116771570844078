//Assessment Templates
import React, { useState } from 'react';

//import { CheckButton, FAIcon } from './Components';

//import { StyleSheet, css } from 'aphrodite';
import { Modal, Alert, Form, Button, Spinner } from 'react-bootstrap';

import { readFileAsync } from '../Utils';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';

const cuid = require('cuid');

const AddTemplate = ({ templates, updateTemplates, dbFunctions, toast }) => {

  const [message, setMessage] = useState('');
  const [show, setShow] = useState(false);

  const [isComplete, setIsComplete] = useState(false);

  const [formValues, setFormValues] = useState({});
  //const [formInvalid, setFormInvalid] = useState({});

  const [assessment, setAssessment] = useState();
  const [addingTemplate, setAddingTemplate] = useState(false);


  const showModal = s => {

    const sh = s === true || s === false ? s : !show

    setShow(sh);
    if(!sh) clear();
  }

  const setFormVal = (name, check) => e => {
    const value = (typeof e === 'object' && e.target) ? e.target.value : e;

    setFormValues({ ...formValues, [name]: value });

    /*if (check) {
      setFormInvalid({ [name]: (value !== '' && !check(value)) });
    }*/
  }

  //const enterOK = e => e && e.key === 'Enter' && addTemplate();

  //const sv = f => e => f(e.target.value);

  const clear = () => {
    setFormValues({});
    setMessage();
    setAssessment();
    setIsComplete(false);
  }

  /**
   * Load into browser
   * @param {*} e 
   */
  const addDoc = async e => {
    clear();

    if (e.target.files && e.target.files[0]) {
      //if(this._mounted!==false) this.setState({imloading: true});

      const file = e.target.files[0];
      try {
        var json = await readFileAsync(file);

        console.log("Import file: ", json);

        if (!(json && json.length && json[0].type === "Assessment")) {
          throw new Error("File is not valid Assessment");
        }

        const name = json && json.length && json[0].values && json[0].values.name;

        //if(this._mounted!==false) this.setState({notice: name?`${name}: ${json?`${json.length} rows`:''}`:"Incorrect format", file: name?json:null});

        setMessage(`Template parsed${name ? ': ' + name : ''} [${json.length} rows]`);

        if (name) {
          setFormVal("templatename")(name);
          setAssessment(json);
        }

        //e.target.value="";

        //this.setShow(true);
      }
      catch (err) {
        console.log("Assessment Import Error: ", err);

        setMessage(err.message);

        return err;
      }
    }
  }

  /**
   * Upload to server
   */
  const addTemplate = async () => {

    if (!assessment) return;

    var ass;

    setAddingTemplate(true);

    if (assessment.values.name !== formValues.templatename) {
      ass = assessment.map((a, i) => i ? a : { ...a, values: { ...a.values, name: formValues.templatename } });
      setAssessment(ass);
    }

    //Wipe Action Summary
    ///Action Types
    const atypes = [
      '_riskactions',
      '_precautionmeasures',
      '_extinguishers',
      '_escaperoutes',
      '_licences',
      '_sitesections'
    ];

    ///Object version of atypes with empty array values
    const atwipe = atypes.reduce((a, c) => ({ ...a, [c]: [] }), {});

    console.log("atwipe: ", atwipe);

    ass = (ass || assessment).map(el => el?.type === "ActionSummary"
      ? { ...el, values: { ...el.values, ...atwipe } }
      : el
    );

    const _id = `template-${cuid()}`;

    const response = await dbFunctions.dbPut({ db: 'admin', doc: { _id, template: ass, name: formValues.templatename, description: formValues.templatedescription, added: Date.now() } });

    if (!response) {
      setMessage("Failed to add template");
      setAddingTemplate(false);
      return;
    }

    var newdoc = {
      _id: 'templateList',
      templates: (templates || []).concat({
        _id,
        name: formValues.templatename,
        description: formValues.templatedescription,
        customer: formValues.templatecustomer,
        notes: formValues.notes,
        when: Date.now()
      })
    };

    if(formValues.setdefault!==false) {
      newdoc = {...newdoc, templates: newdoc.templates.map(t=>({...t, default: t._id===_id}))}
    }

    console.log("Newdoc: ", newdoc);

    const tLUpdate = await dbFunctions.dbUpdate({ db: 'admin', doc: newdoc });

    setAddingTemplate(false);

    clear();

    if (tLUpdate) {
      setIsComplete(true);
      updateTemplates({ tempsin: newdoc });
      showModal(false);
      toast(`Template added`);
    } else {
      setMessage("Error adding template");
    }

  }

  return (

    <div>
      <Button onClick={showModal}>Add Template</Button>

      <Modal show={show} size="lg" onHide={showModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Template</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message && <Alert variant='primary'>
            {message}
          </Alert>}
          <Form>
            <Form.Group>
              <Form.Label>Template Upload</Form.Label>
              <Form.Control as="input" type="file" accept={".json,.fsm"} onChange={addDoc} />
            </Form.Group>
            {assessment &&
              <>
                <Form.Group>
                  <Form.Label>Template Name</Form.Label>
                  <Form.Control as="input" value={formValues.templatename || ''} onChange={setFormVal("templatename")} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Template Description</Form.Label>
                  <Form.Control as="input" value={formValues.templatedescription || ''} onChange={setFormVal("templatedescription")} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Customer</Form.Label>
                  <Form.Control as="input" value={formValues.templatecustomer || ''} onChange={setFormVal("templatecustomer")} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Notes</Form.Label>
                  <Form.Control as="textarea" value={formValues.notes || ''} onChange={setFormVal("notes")} />
                </Form.Group>
                <Form.Group>
                  <Form.Switch id={"setdefaultemplate"} checked={formValues.setdefault !== false} onChange={() => setFormVal('setdefault')(!(formValues.setdefault !== false))} label={'Set to default template'} />
                </Form.Group>
              </>
            }
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {isComplete && <Button type="cancel" variant="success" onClick={showModal}>OK</Button>}
          {!isComplete && <Button type="cancel" variant="secondary" onClick={showModal}>Cancel</Button>}
          {!isComplete && <Button type="submit" variant="primary" disabled={!assessment || addingTemplate} onClick={addTemplate}>{addingTemplate ? <Spinner animation="border" size="sm" /> : ''} Add </Button>}

        </Modal.Footer>
      </Modal>


    </div>

  )
}

export default AddTemplate;