import React, { useState } from 'react';
//import { StyleSheet, css } from 'aphrodite';
import { Button, ButtonGroup, Modal, Form, Spinner } from 'react-bootstrap';

import { FAIcon } from '../Components';

const EditUser = ({ user, className, style, dbFunctions, updateUsers, toast }) => {

  const [show, setShow] = useState(false);
  //const [message, setMessage] = useState('');
  const [useractualname, setUseractualname] = useState(user.actualname || (user.doc)?.fullname || '');
  //const [checked, setChecked] = useState(true);
  const [updating, setUpdating] = useState(false);

  const showModal = s => {
    setShow(s === true || s === false ? s : !show);
  }

  const fs = n => e => n(e.target.value);

  /*const send = type => async () => {
    setSending(type);

    const fields = {
      useremail: user.username,
      useractualname: user.actualname || (user.doc).fullname || ''
    }
  
    //const response = await dbFunctions.dbAdminPost({ query: ['trilogy','custominvite'], doc: {email: formValues.users, name: formValues.uname, oneyear: formValues.one, threeyear: formValues.three }, toast });
    const response = await dbFunctions.dbAdminPost({ query: ['trilogy','sendemail'], doc: {fields, type} });

    if(response) {
      if(response.success) showModal(false);

      toast(response.message);
    } else {
      toast(`Unknown error sending email`);
    }

    setSending(false);
  }*/

  const updateUser = async e => {
    setUpdating(true);

    const fields = {
      useremail: user.username,
      useractualname
    }

    const response = await dbFunctions.dbAdminPost({ query: ['trilogy', 'updateuserdetails'], doc: { fields } });

    if (response) {
      if (response.success) showModal(false);

      toast(response.message);

      updateUsers();
    } else {
      toast(`Unknown error updating user details`);
    }


    setUpdating(false);
  }

  const username = `${user.actualname || (user.doc).fullname || ''} [${user.username}]`;

  return (
    <ButtonGroup className={className} style={style}>

      <Button title="Edit User Details" onClick={showModal} variant="outline-secondary"><FAIcon icon="user" /></Button>

      <Modal show={show} size="lg" onHide={showModal}>
        <Modal.Header>
          <Modal.Title>Edit Users: {username}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/*message && <Alert variant='primary'>
            {message}
            </Alert>*/}

          <Form.Group>
            <Form.Label>User (actual) name:</Form.Label>
            <Form.Control value={useractualname} onChange={fs(setUseractualname)} />
          </Form.Group>

        </Modal.Body>
        <Modal.Footer>
          {/*<Button type="button" variant="danger" onClick={doDelete} disabled={sending}>{sending ? <Spinner as="span" animation="border" size="sm" /> : <FAIcon icon="trash" />} Delete</Button>*/}
          <Button type="button" variant="primary" onClick={updateUser} disabled={updating}>{updating ? <Spinner as="span" animation="border" size="sm" /> : <FAIcon icon="user" />} Update</Button>
          <Button type="button" variant="secondary" onClick={showModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </ButtonGroup>
  )
}

export default EditUser;