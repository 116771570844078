import React, { useState, useEffect } from 'react';

import { Table, Modal, Form, Button, Spinner, InputGroup, Dropdown } from 'react-bootstrap';

import { validateEmail } from '../Utils'; //validateNotBlank
import { FAIcon, CheckButton } from '../Components';

/**
 * Share / unshare site to users
 * @param {*} param0 
 */
const Sharing = ({ siteid, sitename, users = [], updateSite, updateUsers, toast, userList, dbFunctions, className }) => {

  const [show, setShow] = useState(false);
  //const [message, setMessage] = useState('');
  const [checked, setChecked] = useState([]);
  const [deleting, setDeleting] = useState(false);
  const [adding, setAdding] = useState(false);

  const [newuser, setNewuser] = useState('');
  const [actualname, setActualname] = useState('');
  const [useremailvalid, setUseremailvalid] = useState();

  const showModal = s => {
    const sh = s === true || s === false ? s : !show;
    setShow(sh);

    clear();
  }

  const clear = () => {
    setNewuser('');
    setActualname('');
    setDeleting(false);
    setAdding(false);
  }

  const fs = n => e => n(e.target.value);

  //Add/remove user from checked list
  const switchUser = u => e => {
    setChecked(
      checked.includes(u)
        ? checked.filter(c => c !== u)
        : [].concat(checked, u)
    )
  }

  //Set newuser value and run validation
  const setUser = e => {
    const uv = e.target.value;

    setNewuser(uv);
    setUseremailvalid(validateEmail(uv));
  }

  //Give new or existing user permissions to access a site
  const share = async () => {
    setAdding(true);

    //newuser
    //site=siteid, sharetoemail=newuser

    const doShare = await dbFunctions.dbAdminPost({ query: ['trilogy', 'sharesite'], doc: { site: siteid, sharetoemail: newuser, actualname } });

    if (doShare) {
      toast(doShare.message);
      if (doShare.success) {
        updateSite();
        //updateUsers();
        clear();
      }
    }
    else {
      toast(`Failed to share site to ${newuser}`);
    }

    setAdding(false);
  }

  //Remove a user's permission to access a site
  const unShare = uid => async () => {
    setDeleting(uid);

    const doc = { site: siteid, userid: uid, inform: checked.includes(uid) };

    const doUnshare = await dbFunctions.dbAdminPost({ query: ['trilogy', 'unsharesite'], doc });

    console.log("doUnshare: ", doc, doUnshare);

    if (doUnshare) {
      toast(doUnshare.message);
      if (doUnshare.success) {
        updateSite();
        //updateUsers();
      }
    }
    else {
      toast(`Failed to unshare site from ${newuser}`);
    }

    setDeleting(false);
  }

  const populate = (email, name) => () => {
    setNewuser(email);
    setUseremailvalid(validateEmail(email));
    setActualname(name);
  }

  //!Rechecks any set to false - change to email: true/false
  useEffect(() => {
    setChecked(users.map(u => u.username).sort())
  }, [users]);

  //console.log("emv: ", useremailvalid, !!useremailvalid);

  return (
    <>
      <button className="plain left" title="Edit Users" onClick={showModal}>
        {(users || []).map(u =>
          <div key={u.id} id={u.doc?.userdb} style={{ marginBottom: '0.5em' }}>
            <div>{u.actualname || u?.doc?.fullname || ''}</div>
            <div>[{u.username}]</div>
          </div>
        )}
      </button>

      <Modal show={show} size="lg" onHide={showModal}>
        <Modal.Header closeButton>
          <Modal.Title>Users: {sitename}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/*message && <Alert variant='primary'>
              {message}
              </Alert>*/}

          <Table striped bordered hover className="stTable">
            <thead>
              <tr>
                {/*<th>Creator</th>*/}
                <th>User</th>
                <th>Notify?</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {users.map(u => {
                const us = u.username;
                return (
                  <tr key={u.id}>
                    <td>{`${u.actualname || u?.doc?.fullname || ''} [${u.username}]`}</td>
                    <td><Form.Switch key={u.id} id={`dnot_${us}`} checked={checked.includes(us)} onChange={switchUser(us)} label={''} /></td>
                    <td>
                      {deleting === u.id ? <Spinner as="span" animation="border" size="sm" /> : <CheckButton title="Unshare from user" icon="ban" onClick={unShare(u.username)} check={`Unshare ${checked.includes(us) ? 'and notify' : `(don't notify)`}?`} disabled={deleting} />}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>

          <Form.Group>
            <Form.Label>Add User</Form.Label>
            <InputGroup>
                <Dropdown>
                  <Dropdown.Toggle variant="info" title="Existing User">
                    {'Existing'}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {
                      userList.map(u =>
                        <Dropdown.Item key={u.username} onClick={populate(u.username, u.actualname)}>{u.actualname} &lt;{u.username}&gt;</Dropdown.Item>
                      )
                    }
                  </Dropdown.Menu>
                </Dropdown>
              <Form.Control placeholder="Email" as="input" type="email" value={newuser || ''} onChange={setUser} />
              <Form.Control placeholder="Actual Name" as="input" value={actualname || ''} onChange={fs(setActualname)} />
                <Button onClick={share} variant="primary" disabled={useremailvalid !== true || adding}>{adding ? <Spinner as="span" animation="border" size="sm" /> : <FAIcon icon='plus-circle' />} Add</Button>
            </InputGroup>
            <Form.Text className="text-muted">{newuser && useremailvalid}</Form.Text>
          </Form.Group>

        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Sharing;