import React, { useState, useEffect } from 'react';
//import { StyleSheet, css } from 'aphrodite';
import { Tabs, Tab, Button } from 'react-bootstrap';

import './App.css';
import './style.scss';

import 'bootstrap/dist/css/bootstrap.min.css';

import 'font-awesome/css/font-awesome.min.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { dbFunctions, isArray } from './Utils';
import { FAIcon, Logo, Logo2 } from './Components';

import { Login, logout } from './Login';
import { Users } from './Users';
import Sites from './Sites';
import Reports from './Reports';
import Templates from './Templates'
import { Files } from './Files';
import DBEdit from './DBEdit';
import Settings from './Settings';

import packageJson from '../package.json';
global.appVersion = packageJson.version;

const maintitle = process.env.REACT_APP_MAINTITLE || 'Firesmart Admin';
const logono = process.env.REACT_APP_LOGONO || 0;


//const subsite='alpha';


/*const styles = StyleSheet.create({
  stgrid: {

  },
  small: {
    fontSize: '0.7em'
  },
  stTable: {
    thead: {
      th: {
        verticalAlign: 'top'
      }
    }
  }
});*/


const Home = () => {

  return (
    <div className="tab-body">
      <h3>Welcome to {maintitle}</h3>
      <p>This system is purely for use by Firesmart/Trilogy staff.</p>
      <p>It allows the creation and manipulation of {process.env.REACT_APP_NAME} Sites and Users.</p>
    </div>
  )
}

/**
 * Get Settings data from listed documents
 */
  const settingsfiles = ['email', 'general', 'prices'];
  const getSettings = async ({ dbf, setSettings, setSettingsObj }) => {
    try {
      var sd = await Promise.all(settingsfiles.map(s=>
        dbf.dbFetch({ query: ['admin', `settings-${s}`] })
      ));
    }
    catch (err) {
      console.log("Failed to get settings data! ", err);
      return;
    }

    const sout = {};

    if(sd && sd.length) {
      sd.forEach(s=>{
        sout[s._id] = s.values;
      })
    }

    //Settings to Object
    const so = {};
    Object.keys(sout).forEach(k=>{
      const oo = {};

      if(isArray(sout[k])) {
        sout[k].forEach(val=>{
          oo[val.id] = val;
        })
      }

      so[k] = oo;
    });

    setSettings(sout);
    setSettingsObj(so);

    //console.log("sout, so: ", sout, so);
  }

/**
 * Get site data from server
 * //! Grab site data then update individual sites
 * @param {*} uname 
 * @param {*} pword 
 */
const getSites = async ({ justusers, siteid, sitepreview, setDataUpdating, setSiteList, setUsers, setSiteArchives, dbf, force=false } = {}) => {

  console.log("Updating sites...", justusers, siteid);

  //setSitesUpdating(true);
  if(!justusers) setDataUpdating('sites')(true);
  setDataUpdating('users')(true);

  const siteData = await dbf.dbAdminPost( {query: ['sites', 'tggetsitedata'], doc: { justusers, siteid, sitepreview, force }} );

  const sitedata = siteData.sitedata;

  console.log("sitedata: ", siteData);

  if(!justusers && sitedata && !(typeof sitedata==='object' && sitedata.success===false)) {

    if(siteid) {
      //console.log("Site id specified..!", sitedata);
      //Deleted / not found
      if(sitedata.length && sitedata[0].deleted) {
        console.log("Site deleted..!");
        setSiteList(sL=>sL.filter(si=>si.id!==siteid));
      }
      else if(sitedata.length) {

        const sd = sitedata[0];

        //Update or add
        setSiteList(sL=>{
          var found=false;

          const upout = sL.map(si=>{
            if(si.id===sd.id) {

              found=true;
              return sd;
            } else return si;
          });

          const out = found?upout:sL.concat(sd);

          return out;
        });

      }
    }
    else setSiteList(sitedata);
  }

  if(siteData.userdata) setUsers(siteData.userdata);
  if(siteData.siteArchive) setSiteArchives(siteData.siteArchive);

  //setSitesUpdating(false);
  setDataUpdating('sites')(false);
  setDataUpdating('users')(false);
}


/**
 * Get template data from server
 * @param {*} tempsin 
 */
  const getTemplates = async ({tempsin, setTemplates, toast, dbf}) => {
  console.log("Updating Templates...");

  //if(!(username && password)) return;

  try {
    var temps = tempsin || await dbf.dbFetch( {query: ['admin', 'templateList']} );
  }
  catch (err) {
    console.log("Failed to update templates! ", err);
    toast("Failed to update templates! "+err);
    return;
  }

  //console.log("Templates: ", temps);

  if(temps) {
    setTemplates(temps.templates);
  } 
}


/**
 * Get Site Archive data
 * @returns 
 */
const getSiteArchives = async ({ setUpdatingSites, setSiteArchives, dbFunctions, toast }) => {
  if(setUpdatingSites) setUpdatingSites(true);

  const sa = await dbFunctions.dbAdminPost( { query: ['sites', 'tggetsitearchivedata'], doc: {} } );

  console.log("siteArchives: ", sa);

  if(sa.success) {
    setSiteArchives(sa.siteArchive);
  } else {
    toast(sa.message);
  }

  if(setUpdatingSites) setUpdatingSites(false);
}


const Admin = () => {

  const [isLoggedIn, setLoggedIn] = useState(false);
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');

  const [dbf, setDbf] = useState();

  const [siteList, setSiteList] = useState([]);
  //const [sitesUpdating, setSitesUpdating] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [users, setUsers] = useState([]);
  const [siteArchives, setSiteArchives] = useState([]);
  const [settings, setSettings] = useState({});
  const [settingsObj, setSettingsObj] = useState({});

  const [updating, setUpdating] = useState({});

  const doLogin = async (data) => {

    if(data) {
      setLoggedIn(true);
      setPassword(data.password);
      setUsername(data.username);

      //getSettings(data.username, data.password);
      //getTemplates(undefined, data.username, data.password);
      //getUsers(data.username, data.password);
      //getSites(data.username, data.password);
      setDbf(dbFunctions({username: data.username, password: data.password, logout: ()=>{setLoggedIn(false)} }));
    }
    else {
      setLoggedIn(false);
      setPassword('');
      setUsername('');

      setSiteList([]);
      setUsers([]);
      setTemplates([]);
      setDbf();
    }
  }

  //When database functions become available, update data
  useEffect(()=>{
    if(dbf) {

      const getStuff = async () => {
        getSettings({setSettings, setSettingsObj, dbf});
        getTemplates({setTemplates, toast, dbf});
        //getUsers(dbf, setUsers, setDataUpdating)();
        //await getSites({sitepreview: true, setDataUpdating, setSiteList, setUsers, dbf});
        getSites({setDataUpdating, setSiteList, setUsers, setSiteArchives, dbf});
      }

      getStuff();

      dbf.dbFollow();
    }
  }, [dbf])

  //Set updating flag for given dataset
  const setDataUpdating = name => (updating=true) =>{
    setUpdating(d=>({...d, [name]: updating}));
  }

  //const adminPost = ({query, doc}) => dbAdminPost({query, doc, username, password});

  /*const adminSubmitFile = ({ query, formData=new FormData() }) => {
      formData.set('username', username);
      formData.set('password', password);

    return dbAdminSubmitFile({ query, formData });
  }*/

  /**
   * Get data about an Assesment from a given Site
   * @param {*} siteid 
   * @param {*} assessmentid 
   * @param {*} uname 
   * @param {*} pword 
   */
  /*const getAssessmentData = async (siteid, assessmentid) => {
    const assessment = await dbf.dbFetch({query: [siteid, assessmentid]}); //!Bulk

    if(assessment.error || !assessment.type || assessment.type!=='Assessment') return false;

    const adquery = { query: [siteid, `AssessmentData-${assessmentid}`] };
    const assessmentData = await dbf.dbFetch(adquery);

    if (assessmentData.error) return assessment;

    return ({ ...assessment, assessmentData });
  }*/

  /**
   * Query an individual site
   * @param {*} s SiteID
   * @param {*} userData 
   * @param {*} uname 
   * @param {*} pword 
   */
  /*const getSiteData = async (siteid, uname=username, pword=password) => {
    const site = await dbFetch({ query: [siteid, 'site'], username: uname, password: pword });

    if(!site) return false;

    const assessments = ( site.elements && site.elements.length && (await Promise.all(site.elements.map(assessmentid => getAssessmentData(siteid, assessmentid, uname, pword)))).filter(a=>a) ) || [];

    const security = await dbFetch({ query: [siteid, '_security'], username: uname, password: pword });

    //const users = userData.filter(u=>(((security || {}).members || {}).names || []).includes(u.username));
    const users = (((security || {}).members || {}).names || []);

    return {
      id: siteid,
      value: siteid,
      site,
      assessments,
      security,
      users
    }
  }*/

  /**
   * Update an individual site
   * @param {*} siteid 
   */
  /*const updateSite = async (siteid) => {
    //const sitedata = await getSiteData(siteid);
    const sitedata = await dbf.dbAdminPost( {query: ['sites', 'tggetsitedata'], doc: {siteid} } );

    console.log("sitedata: ", sitedata);

    //Deleted / not found
    if(!sitedata || (sitedata.length && sitedata[0].deleted)) {
      console.log("Site deleted..!");
      return setSiteList(sL=>sL.filter(si=>si.id!==siteid));
    }

    if(sitedata && sitedata.length) {

      const sd = sitedata[0];

      //Update or add
      return setSiteList(sL=>{
        let found=false;

        const upout = sL.map(si=>{
          if(si.id===siteid) {
            found=true;
            return sd;
          } else return si;
        });

        return found?upout:sL.concat(sd);
      });

    }
  }
  */

  const updateSite = (siteid) => getSites({siteid, setDataUpdating, setSiteList, setUsers, setSiteArchives, dbf});

  //!!Should probably be able to update a subset of users and also the sites to which they have access
  const getUsers = () => getSites({ justusers: true, setDataUpdating, setSiteList, setUsers, setSiteArchives, dbf });

  const getArchives = ({ setUpdatingSites }={}) => getSiteArchives({ setUpdatingSites, setSiteArchives, toast, dbFunctions: dbf });

  const updateSetting = _id => async values => {
    console.log("updateSetting: ", _id, values);

    const ok = await dbf.dbUpdate({ db: 'admin', doc: {_id, values} });

    toast(ok?`Settings Saved (${_id})`:`Error saving settings!`);

    if(ok) {
      //!Just update this _id
      getSettings({setSettings, setSettingsObj, dbf});
      return true;
    }

    return false;
  }

  /*const getSetting = (h=[]) => {
    if(!h || !h.length) return settings;

    let out = settings;

    h.forEach(s=>{
      out = out
    })

  }*/

  const tablist = [
    {id: 'home', title: 'Home', content: <Home />},
    {id: 'users', title: 'Users', content: <Users users={users} updateUsers={getUsers} usersUpdating={updating['users']} dbFunctions={dbf} toast={toast} />},
    {id: 'sites', title: 'Sites', content: <Sites username={username} password={password} templates={templates} siteList={siteList} userList={users} setSiteArchives={setSiteArchives} siteArchives={siteArchives} getSiteArchives={getArchives} updateSites={v=>getSites({...v, setDataUpdating, setSiteList, setUsers, setSiteArchives, dbf, force: true})} updateUsers={getUsers} updateSite={updateSite} sitesUpdating={updating['sites']} dbFunctions={dbf} settings={settingsObj} toast={toast} />},
    {id: 'reports', title: 'Reports', content: <Reports dbFunctions={dbf} toast={toast} />},
    {id: 'templates', title: 'Templates', content: <Templates templates={templates} updateTemplates={v=>getTemplates({...v, setTemplates, toast, dbf})} dbFunctions={dbf} toast={toast} />},
    {id: 'files', title: 'Files', content: <Files dbFunctions={dbf} toast={toast} />},
    {id: 'dbedit', title: 'DB Edit', content: <DBEdit dbFunctions={dbf} toast={toast} siteList={siteList} />},
    {id: 'settings', title: 'Settings', content: <Settings settings={settings} updateSetting={updateSetting} dbFunctions={dbf} toast={toast} />}
  ];

  return (
    <div className="Admin">
      <ToastContainer />
      <header className="Admin-header">
        <h1>{logono ? <Logo2 /> : <Logo />} {maintitle}</h1>
      </header>
      <main>
        <div id="topbar">          
          {isLoggedIn
            ?<Tabs defaultActiveKey={tablist[0].id} id="admintabs" >
              {tablist.map(t=>
                <Tab key={t.id} eventKey={t.id} title={<span className={updating[t.id]?'pulsate':''}>{t.title}</span>}>
                  {t.content}
                </Tab>
              )}
            </Tabs>
            :<Login success={doLogin} />
          }
          {isLoggedIn && <Button variant="outline-secondary" onClick={()=>logout(()=>{setLoggedIn(false)})} style={{position: 'absolute', right: '0.5rem', top: '0.5rem'}}><FAIcon icon="times" /></Button>}
        </div>
      </main>
      <footer style={{position: 'fixed', bottom: 0, textAlign: 'right', width: '100%', padding: '0.1em 0.2em', color: '#ccc'}}>
        <span>v{global.appVersion}</span>
      </footer>
    </div>
  );
}

export default Admin;