import React, { useState } from 'react';

import { Modal, Button, ButtonGroup, InputGroup} from 'react-bootstrap';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import { Icon, CheckButton } from '../Components';

import { ukdate, dtime } from '../Utils'; 

const adminserver = process.env.REACT_APP_ADMIN_SERVER;

const Reports = ({ reports = [], siteid, assid, dbFunctions, updateSite, toast, className, style }) => {
    const [show, setShow] = useState(false);
  
    //const [message, setMessage] = useState('');
  
    const showModal = s => setShow(s === true || s === false ? s : !show);
  
    const openReport = report => () => {
      window.open(`${adminserver}/reports/${siteid}/${assid}/${report}`, '_blank');
    }
  
    const deleteReport = report => async () => {
      const site = siteid;
      const survey = assid;
  
      //console.log("Delete report: ", site, survey, report);
  
      const dr = await dbFunctions.dbAdminPost({ query: ['sites', 'report'], method: 'delete', doc: { site, survey, report }, toast });
  
      if (dr) {
        toast(dr.message);
        if (dr.success) updateSite();
      }
    }
  
    const previewError = err => {
      console.log("Failed to load PDF: ", err);
    }
  
    if (!(reports?.length)) return null;
  
    return (
      <ButtonGroup className={className} style={style}>
  
        <Button title="All Reports" onClick={showModal} variant="outline-secondary"><Icon icon="file-text-o" />{reports.length}</Button>
  
        <Modal show={show} size="lg" onHide={showModal}>
          <Modal.Header closeButton>
            <Modal.Title>Reports</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {reports?.sort((a,b)=>(a.when || 0)>(b.when || 0)?-1:1)?.map(r =>
              <div key={r.id}>
                <InputGroup>
                  <Button title="Open Report" onClick={openReport(r.id)} variant="outline-secondary">
                    <Document file={`${adminserver}/reports/${siteid}/${assid}/${r.id}`} onLoadError={previewError}>
                      <Page pageNumber={1} width={200} />
                    </Document>
                  </Button>
                  <InputGroup.Text className="plain">{ukdate(r.when)}<br />{dtime(r.when)}</InputGroup.Text>
                  <CheckButton title="Delete Report" onClick={deleteReport(r.id)} check="Delete Report?" />
                </InputGroup>
              </div>
            )}
  
          </Modal.Body>
          <Modal.Footer>
  
          </Modal.Footer>
        </Modal>
      </ButtonGroup>
    )
  }

export default Reports;