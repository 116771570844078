import React, { useState, useEffect } from 'react';
//import { StyleSheet, css } from 'aphrodite';
import { Form, Button, InputGroup } from 'react-bootstrap';

//import { ukdate, dbFetch, dbAdminPost, validateEmail, validateNotBlank } from './Utils';
//import { TopMessage, Searcher, FAIcon } from './Components';

import generaloptions from './generaloptions.js';

/**
 * Display / manipulate email settings
 * @param {*} param0 
 */
const SettingsGeneral = ({ settings, update, toast }) => {

  //const [options, setOptions] = useState(generaloptions);

  //Values of contents/subject of each email option *if* they have been changed by the user
  //const [contents, setContents] = useState({});
  //const [subject, setSubject] = useState({});
  const [values, setValues] = useState([]);

  const [updated, setUpdated] = useState(false);
  //const [saving, setSaving] = useState(false);

  //Values of Test fields
  //const [testValues, setTestValues] = useState({});
  //const [testEmail, setTestEmail] = useState('info@firesmart.co.uk');

  //True if sending of a test email is in progress
  //const [sending, setSending] = useState(false);

  //Carat position within textarea/subject for insertion of fields
  //const [cursorpos, setCursorpos] = useState({});
  //const [subjectcursorpos, setSubjectcursorpos] = useState({});


  //const [inSubject, setInSubject] = useState(false);
  //const [] //Subject/Content

  useEffect(()=>{ 
    /*if(settings)*/ setValues(
      generaloptions.map(opt=>({
        id: opt.id,
        value: ((settings || []).find(se=>se.id===opt.id) || {}).value
      }))
    )
  }, [settings])

  const updateValue = field => value => {
    const v = typeof value==='object'?value.target.value:value;
    setUpdated(true);

    const vout = values.map(vf=>vf.id===field?{id: field, value: v}:vf);

    setValues(vout);
  }

  const save = async () => {

    const ok = await update(values);

    if(ok) {
      setUpdated(false);
    }
  }

  //const sfv = f => e => f(e.target.value);

  return (
    <div>
      <div className="tab-body">
        General / Misc Settings
      </div>

      <div className="tab-body">
        <Form>
          {generaloptions.map(opt=>
            <Form.Group key={opt.id}>
              <Form.Label>{opt.title}</Form.Label>
              <InputGroup>
                {opt.prepend && <InputGroup.Text>{opt.prepend}</InputGroup.Text>}
                
                <Form.Control as="input" type={opt.type || 'text'} value={((values || []).find(v=>v.id===opt.id) || {}).value || ''} onChange={updateValue(opt.id)} />
                
                {opt.append && <InputGroup.Text>{opt.append}</InputGroup.Text>}

              </InputGroup>
              {/*!Validate? <Form.Text className="text-muted">{valid('one')}</Form.Text>*/}
            </Form.Group>
          )}

          {updated && <Button type="button" variant="primary" onClick={save}>Save</Button>}
        </Form>
      </div>

      {/*<Tabs defaultActiveKey={emailData && emailData.length && emailData[0].id} id="settingstabs">
        {emailData.map(t =>
          <Tab key={t.id} eventKey={t.id} title={t.title}>
            <Form style={{ padding: '1em' }}>
              <div className="small">[{t.id}]</div>
              <div>{t.description}</div>

              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Field</th>
                    <th>Description</th>
                    <th>Test Value</th>
                  </tr>
                </thead>
                <tbody>
                  {(t.fields || []).map(f =>
                    <tr key={f.id}>
                      <td><Button type="button" variant="link" onClick={insertField(t.id, f.id)}>{`{{`}{f.id}{`}}`}</Button></td>
                      <td>{f.description}</td>
                      <td><Form.Control onChange={setTestVal(t.id, f.id)} defaultValue={f.testvalue} /></td>
                    </tr>
                  )}
                  <tr>
                    <td></td>
                    <td colSpan="2">
                      <Form.Group as={Row}>
                        <Form.Label column sm="2">Test to: </Form.Label>
                        <Col sm="10">
                          <InputGroup>
                            <Form.Control type="email" placeholder="Email" value={testEmail} onChange={e => setTestEmail(e.target.value)} />
                            <InputGroup.Append>
                              <Button type="button" variant="outline-secondary" onClick={sendTest(t.id)}>{sending && sending.id === t.id && sending.sending && <Spinner as="span" animation="border" size="sm" />} Test</Button>
                            </InputGroup.Append>
                          </InputGroup>
                        </Col>
                      </Form.Group>
                    </td>
                  </tr>
                </tbody>
              </Table>

              {t.subject !== false &&
                <Form.Group>
                  <Form.Label>Subject</Form.Label>
                  <Form.Control value={subject[t.id] || t.subject || ''} onChange={updateSubject(t.id)} onClick={updateCursorPos(t.id, true)} onKeyUp={updateCursorPos(t.id, true)} />
                </Form.Group>}

              <Form.Group>
                <Form.Label>Content</Form.Label>
                <Form.Control as="textarea" value={contents[t.id] || t.content || ''} rows={20} onChange={updateContents(t.id)} onClick={updateCursorPos(t.id)} onKeyUp={updateCursorPos(t.id)} />
              </Form.Group>

              <Form.Group>
                {(contents[t.id] || subject[t.id]) && <Button type="button" variant="primary" onClick={save(t.id)}>Save</Button>}
              </Form.Group>
            </Form>

          </Tab>
        )}
    </Tabs>*/}


    </div>
  )
}

export default SettingsGeneral;