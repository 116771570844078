import React, { useState } from 'react';

import { Modal, Alert, Form, Button, Spinner } from 'react-bootstrap';

import { FAIcon } from '../Components';


const Licence = ({ licence, dbFunctions, updateSite, toast }) => {
    const [show, setShow] = useState(false);
  
    const [updated, setUpdated] = useState(false);
    const [submitting, setSubmitting] = useState(false);
  
    const [newExpiry, setNewExpiry] = useState();
  
    const showModal = s => {
      const ns = s===true || s===false?s:!show;
      clear();
      setShow(ns);
    }
  
    const clear = () => {
      setUpdated(false);
      setSubmitting(false);
    }
  
    const setExpires = e => {
  
      setUpdated(true);
      setNewExpiry(new Date(e.target.value).getTime());
  
      /*const expiry = new Date(e.target.value).getTime();
      const siteid = licence.siteid;
  
      console.log("Expiry: ", expiry, licence.siteid);
  
      const doUpdate = await dbFunctions.dbAdminPost({ query: ['trilogy','updatelicence'], doc: {siteid, expiry} });*/
  
    }
  
    const doUpdate = async () => {
      setSubmitting(true);
      const siteid = licence.siteid;
      const expires = newExpiry || licence.expires;
      const licenceid = licence._id;
  
      const doc = {siteid, expires, licenceid};
  
      if(licence.notcreated) {
        doc.user = licence.user;
        doc.when = licence.when;
        doc.notcreated = true;
      }
  
      var update;
  
      try {
        update = await dbFunctions.dbAdminPost({ query: ['trilogy','updatelicence'], doc });
      } catch (err) {
        toast(`Error updating site: ${err}`);
      }
  
      if(update) {
        if(update.success) {
          toast(`Licence updated`);
          updateSite();
          showModal(false);
        }
        else {
          toast(`Error updating licence: ${update.message}`);
        }
      } else {
        toast(`Unknown error updating licence`);
      }
  
      setSubmitting(false);
    }

    const expire = () => {

    }
  
    const notcreated = licence.notcreated;
  
    const expires = new Date(licence.expires).toISOString().substring(0, 10);

    return (
      <>
      <Button variant="link" onClick={showModal}><span className="small" style={notcreated?{color: 'red'}:{}}>Licence: {licence._id}</span></Button>
  
      <Modal show={show} size="lg" onHide={showModal}>
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>Licence</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {notcreated && 
              <Alert variant="danger">
                This licence is not yet a live record - please check the details and update
              </Alert>}
            <Form.Group>
              <Form.Label>Created for user: </Form.Label>
              <Form.Control as="input" disabled defaultValue={licence.user || ''} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Creation Date: </Form.Label>
              <Form.Control as="input" type="date" disabled defaultValue={new Date(licence.when || 0).toISOString().substring(0, 10)} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Expiry Date: </Form.Label>
              <Form.Control as="input" type="date" defaultValue={expires} onChange={setExpires} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" variant="info" onClick={expire}>🕑 Expire Now</Button>
            <Button type="button" variant="secondary" onClick={showModal}>Cancel</Button>
            {(updated || notcreated) && <Button type="button" variant="primary" onClick={doUpdate} disabled={submitting}>{submitting ? <Spinner as="span" animation="border" size="sm" /> : <FAIcon icon="file-text-o" />} Update</Button>}
          </Modal.Footer>
        </Form>
      </Modal>
      </>
    )
  }

  export default Licence;