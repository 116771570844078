import React, { useState, useMemo, useEffect } from 'react';

import { Modal, Form, Button, ButtonGroup, InputGroup, Spinner, Table, Dropdown } from 'react-bootstrap';

import { humanFileSize, ukdate, dtime } from '../Utils'; //validateNotBlank
import { FAIcon, CheckButton, Searcher, Icon } from '../Components';


/**
 * Modal for archiving an assessment
 * @param {*} param0 
 * @returns 
 */
const ArchiveModal = ({ AssessmentName, siteid, assid, dbFunctions, updateSite, archive = [], toast, style, className }) => {

  const [show, setShow] = useState(false);
  const [doDelete, setDoDelete] = useState(false);
  const [doArchiveCurrent, setDoArchiveCurrent] = useState(true);

  const [archiving, setArchiving] = useState(false);
  const [restoring, setRestoring] = useState({});


  const setIsRestoring = (id, v) => setRestoring(a => ({ ...a, [id]: v }))

  //const isArchiving = id => archiving[id];
  //const isRestoring = id => restoring[id];

  const showModal = s => setShow(s === true || s === false ? s : !show);

  const setDelete = () => setDoDelete(!doDelete);
  const setArchiveCurrent = () => setDoArchiveCurrent(!doArchiveCurrent);

  const archiveAssessment = async () => {
    setArchiving(true);

    const arc = await dbFunctions.dbAdminPost({ query: ['sites', 'archiveAssessment'], doc: { siteid, surveyid: assid, deleteFromSite: doDelete } });

    console.log("Archive: ", assid, arc);

    if (arc) {
      if (arc.message) toast(arc.message);

      if (arc.success) updateSite();
    }

    setArchiving(false);
  }

  const restoreAssessment = (archiveid) => async () => {

    setIsRestoring(archiveid, true);

    const arc = await dbFunctions.dbAdminPost({ query: ['sites', 'restoreAssessment'], doc: { siteid, surveyid: assid, archiveid, archiveCurrent: doArchiveCurrent } });

    console.log("Restore Archive: ", archiveid, arc);

    if (arc) {
      if (arc.message) toast(arc.message);

      if (arc.success) updateSite();
    }

    setIsRestoring(archiveid, false);
  }

  return (
    <ButtonGroup className={className} style={style}>

      <Button title="Archive / Restore Assessment" onClick={showModal} variant="outline-secondary"><FAIcon icon="archive" count={archive?.length || ''} />…</Button>

      <Modal show={show} size="lg" onHide={showModal}>
        <Modal.Header closeButton>
          <Modal.Title>Assessment Archive: {AssessmentName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h4>
            Create a new archive
          </h4>

          <InputGroup>
            <div className="form-control">
              <Form.Switch id={`delchek_${assid}`} checked={doDelete} onChange={setDelete} label="Delete from site after creating archive?" />
            </div>
            <Button type="button" variant="warning" onClick={archiveAssessment}>{archiving ? <Spinner as="span" animation="border" size="sm" /> : <FAIcon icon="archive" />} Archive</Button>
          </InputGroup>

          {archive?.length > 0 && <>

            <h4 style={{ marginTop: "0.75em" }}>
              Restore an existing archive
            </h4>

            <InputGroup>
              <div className="form-control">
                <Form.Switch id={`archcur_${assid}`} checked={doArchiveCurrent} onChange={setArchiveCurrent} label="Auto archive current Assessment?" />
              </div>
            </InputGroup>


            {archive.sort((a, b) => a.when < b.when ? 1 : -1).map(a => {
              const dt = new Date(Number(a.when)).toString();

              return (
                <InputGroup key={`archlist_${a.archiveid}`}>
                  <div className="form-control">
                    {dt.substring(0, 24)} {a.size && `[${humanFileSize(a.size)}]`}
                  </div>
                  <Button type="button" variant="info" onClick={restoreAssessment(a.archiveid)}>{restoring[a.archiveid] ? <Spinner as="span" animation="border" size="sm" /> : <FAIcon icon="undo" />} Restore</Button>
                </InputGroup>
              )
            })}

          </>}

        </Modal.Body>
        <Modal.Footer>
          <Button type="button" variant="secondary" onClick={showModal}>Close</Button>
          {/*<Button type="button" variant="danger" onClick={archiveAssessment} disabled={deleting}>{deleting ? <Spinner as="span" animation="border" size="sm" /> : <FAIcon icon="trash" />} Delete</Button>*/}
        </Modal.Footer>
      </Modal>
    </ButtonGroup>
  )
}

/**
 * Modal for archiving a site
 * @param {*} param0 
 * @returns 
 */
const SiteArchiveModal = ({ siteid, sitename, users, dbFunctions, toast, updateSite, archives = {}, getSiteArchives, siteArchive, className, style }) => { //assessments = [],
  const [show, setShow] = useState(false);

  const showModal = s => setShow(s === true || s === false ? s : !show);
  const [doDelete, setDoDelete] = useState(true);

  const [archiving, setArchiving] = useState(false);
  const [restoring, setRestoring] = useState({});

  const [updatingSites, setUpdatingSites] = useState(false);

  const [informUser, setInformUser] = useState({});

  const setIsRestoring = (id, v) => setRestoring(a => ({ ...a, [id]: v }))

  const hasArchives = archives && Object.keys(archives).length > 0;

  const setDelete = () => setDoDelete(!doDelete);

  const restoreAssessment = (archiveid) => async () => {

    setIsRestoring(archiveid, true);

    const arc = await dbFunctions.dbAdminPost({ query: ['sites', 'restoreAssessment'], doc: { siteid, archiveid, informUser } });

    console.log("Restore Archive: ", archiveid, arc);

    if (arc) {
      if (arc.message) toast(arc.message);

      if (arc.success) updateSite();
    }

    setIsRestoring(archiveid, false);
  }

  const archiveSite = async () => {
    //! Need to create list of current assessments, archive assessments, then archive Site including list of current users
    //! For restore, restore Site, warn about users that are missing, restore assessments

    const iusers = doDelete 
      ?users.filter(a=>informUser[a.uid]!==false).map(u=>({ email: u.uid, name: u.actualname })) 
      :[];

    setArchiving(true);

    console.log("archive...");

    const arc = await dbFunctions.dbAdminPost({ query: ['sites', 'archiveSite'], doc: { siteid, deleteSite: doDelete, informusers: iusers } });

    console.log("Archive Site: ", siteid, arc);

    if (arc) {
      if (arc.message) toast(arc.message);

      if (arc.success) {
        getSiteArchives({ setUpdatingSites });
        updateSite();
      }
    }

    setArchiving(false);

  }

  const updateArchives = () => {

    //Could be more efficient - we may know there are no archives available for this site

    if(!siteArchive && siteArchive!==false) getSiteArchives({ setUpdatingSites });
  }

  return (
    <ButtonGroup className={className} style={style}>

      <Button title="Archiving" onClick={showModal} variant="outline-secondary"><FAIcon icon="archive" />…</Button>

      <Modal show={show} size="lg" onHide={showModal} onShow={updateArchives}>
        <Modal.Header closeButton>
          <Modal.Title>Archiving {sitename}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Archive Site</h4>
          <InputGroup>
            <div className="form-control">
              <Form.Switch id={`${siteid}_archive_delete`} checked={doDelete} onChange={setDelete} label="Delete from main database after creating archive?" />
            </div>
            {/*<Button type="button" variant="warning" onClick={archiveSite}><FAIcon icon="archive" /> Archive Site</Button>*/}
          </InputGroup>

          {users?.length>0 && doDelete &&
            <div style={{marginTop: '1em'}}>
              <h5>Inform user(s)?</h5>
              <Table striped hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th title="Email user to inform them that the site has been removed?">Inform?</th>
                </tr>
              </thead>
              <tbody>{(users || []).map(a =>
                  <tr key={a.uid}>
                    <td>{a.actualname}</td>
                    <td>{a.uid}</td>
                    <td><Form.Switch id={`userinfcheck_${a.uid}`} checked={informUser[a.uid]!==false} onChange={()=>setInformUser(r=>({ ...r, [a.uid]: informUser[a.uid]===false }))} /></td>
                  </tr>
                )}</tbody>
              </Table>
            </div>
          }
          <div style={{textAlign: 'right'}}>
            <CheckButton title={`Archive${doDelete ? ' and Delete' : ''}?`} icon="archive" spinner={archiving} onClick={archiveSite} check={`Archive${doDelete ? ' and Delete' : ''}?`} >Archive</CheckButton>
          </div>

          {hasArchives && <h4 style={{ marginTop: '0.75em' }}>Archived Assessments</h4>}
          {Object.keys(archives).map(id =>
            archives[id].map(a => {
              const dt = new Date(Number(a.when)).toString();

              return (
                <InputGroup key={`archlist_${a.archiveid}`}>
                  <div className="form-control">
                    {a.name} {dt.substring(0, 24)} {a.size && `[${humanFileSize(a.size)}]`}
                  </div>
                  <Button type="button" variant="info" onClick={restoreAssessment(a.archiveid)}>{restoring[id] ? <Spinner as="span" animation="border" size="sm" /> : <FAIcon icon="undo" />} Restore</Button>
                </InputGroup>
              )
            })
          )}

          <br />

          {updatingSites
          ?<div style={{textAlign: "center"}}><Spinner as="span" animation="border" /></div>
          :siteArchive && <>
            <h4>Site Archives</h4>
            {siteArchive?.sites.sort((a, b) => a.when < b.when ? 1 : -1).map(a => {
              const dt = new Date(Number(a.when)).toString();

              return (
                <InputGroup key={`sitearchlist_${a.when}`}>
                  <div className="form-control">
                    {dt.substring(0, 24)}
                  </div>
                  {/*<Button type="button" variant="info" onClick={restoreAssessment(a.archiveid)}>{restoring[a.archiveid] ? <Spinner as="span" animation="border" size="sm" /> : <FAIcon icon="undo" />} Restore</Button>*/}
                </InputGroup>
              )
            })}
            </>}

        </Modal.Body>
        <Modal.Footer>
          <Button type="button" variant="secondary" onClick={showModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </ButtonGroup>
  )
}

/**
 * Modal for restoring archived sites
 * Returns button for positioning in Sites button bar
 * @param {*} param0 
 */
const RestoreSiteModal = ({ dbFunctions, toast, className, style, updateSites, updateSite, setSiteArchives, getSiteArchives, siteArchives }) => {
  const [show, setShow] = useState(false);

  //const [siteArchives, setSiteArchives] = useState([]);
  const [updatingSites, setUpdatingSites] = useState(false);

  const [siteListDefault, setSiteListDefault] = useState([]); //Default sitelist, ordered in default order
  const [sitesFiltered, setSitesFiltered] = useState([]);

  const [site, setSite] = useState(); //Selected Site
  const [archive, setArchive] = useState(); //Selected archive of selected site

  const [archiveExisting, setArchiveExisting] = useState();
  const [restoreArchive, setRestoreArchive] = useState({});
  const [restoreUser, setRestoreUser] = useState({});
  const [informUser, setInformUser] = useState({});


  const [restoring, setRestoring] = useState(false);

  const showModal = s => {

    const ss = s === true || s === false ? s : !show;

    if (ss !== show) {
      setSite(false);
      setArchive(false);
      //setSiteArchives([]);
      setUpdatingSites(false);

      setArchiveExisting();
      setRestoreArchive({});
      setRestoreUser({});
      setInformUser({});

      setRestoring(false);
    }

    setShow(ss);
  }

  const selectSite = s => {
    setSite(s);

    setArchive(s.sites[0]);
  }

  //const hasArchives = true;

  const sl = useMemo(() => siteArchives, [siteArchives]);

  //Set default list of filtered Sites when siteList updates
  //useEffect(() => { setSiteListDefault((sl || []).sort((a, b) => (a.site || {}).when < (b.site || {}).when ? 1 : -1)) }, [sl]);
  useEffect(() => { setSiteListDefault(sl) }, [sl]);

  useEffect(() => { setSitesFiltered(siteListDefault); /*console.log(siteListDefault);*/ }, [siteListDefault]);

  useEffect(()=>setSite(), [sitesFiltered])

  /**
   * Get site archives
   */
  /*const getSiteArchives = async () => {
    setUpdatingSites(true);

    const sa = await dbFunctions.dbAdminPost( { query: ['sites', 'tggetsitearchivedata'], doc: {} } );
    //tggetsitearchivedata
    console.log("siteArchives: ", sa);

    if(sa.success) {
      setSiteArchives(sa.siteArchive);
    } else {
      toast(sa.message);
    }


    setUpdatingSites(false);
  }*/

  const updateArchives = () => getSiteArchives({ setUpdatingSites });

  const restoreSite = async () => {
 
      setRestoring(true);

      const users = archive.userdata.filter(a=>restoreUser[a.email]!==false).map(u=>({ email: u.email, name: u.name, inform: informUser[u.email]!==false, isSuper: u.isSuper }))

      const doc = {
        archiveid: archive._id,
        archiveexisting: archiveExisting!==false,
        assessments: archive.assessmentdata.filter(a=>restoreArchive[a._id]!==false).map(a=>a.archiveid),
        //users: archive.userdata.filter(a=>restoreUser[a.email]!==false).map(a=>a.email),
        //informusers: archive.userdata.filter(a=>informUser[a.email]!==false).map(a=>a.email),
        users
      };

      //const response = await dbFunctions.dbAdminPost({ query: ['trilogy','custominvite'], doc: {email: formValues.users, name: formValues.uname, oneyear: formValues.one, threeyear: formValues.three }, toast });
      const response = await dbFunctions.dbAdminPost({ query: ['sites','restoresite'], doc });

      console.log("doc: ", {doc, assessmentdata: archive.assessmentdata, site, response});

      if(response) {
        if(response.success) showModal(false);
  
        toast(response.message);

        updateSite(archive.siteid);
      } else {
        toast(`Unknown error restoring site`);
      }
  
      setRestoring(false);
  }

  return (
    <ButtonGroup className={className} style={style}>

      <Button title="Archived Sites" onClick={showModal} variant="outline-secondary"><FAIcon icon="archive" /></Button>

      <Modal show={show} size="lg" onHide={showModal} onShow={updateArchives}>
        <Modal.Header closeButton>
          <Modal.Title>Archived Sites</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {updatingSites
          ?<div style={{textAlign: "center"}}><Spinner as="span" animation="border" /></div>
          :<>

          <div style={{ maxHeight: "25rem", overflow: "auto" }}>
            <Table striped bordered hover className="stTable">
              <thead className="stickyHead" style={{ top: 0 }}>
                <tr>
                  <th colSpan={7} style={{ padding: 0 }}>
                    <Searcher
                      values={siteListDefault}
                      onChange={setSitesFiltered}
                      keys={[
                        'sitedoc.values.name',
                        'sitedoc.values.address',
                        'userdata.name',
                        'userdata.email',
                        'assessmentdata.values.licence',
                        'assessmentdata.values.name'
                      ]}
                    ></Searcher>
                  </th>
                </tr>
                <tr>
                  {/*<th>Creator</th>*/}
                  <th title="A version of the site is live">Live</th>
                  <th>Site Name</th>
                  <th>Site Address</th>
                  <th title="Most recent archive date"><Icon>calendar</Icon></th>
                  <th title="Archive count"><Icon>archive</Icon></th>
                  <th title="Number of users"><Icon>user</Icon></th>
                  <th title="Assessments in site"><Icon>list-alt</Icon></th>
                </tr>
              </thead>
              <tbody>{(sitesFiltered || []).map(site => {
                  const archcount = site.sites?.length;
                  const usercount = (site.userdata || []).length;
                  const assmcount = (site.assessmentdata || []).length;

                  return (
                    <tr key={`sarch_${site._id}`} onClick={() => selectSite(site)} style={{ cursor: 'pointer' }}>
                      <td>{site.exists && <Icon>check</Icon>}</td>
                      <td>{site.sitedoc?.values?.name}</td>
                      <td>{site.sitedoc?.values?.address}</td>
                      <td>{ukdate(site.when)}</td>
                      <td>{archcount > 0 && archcount}</td>
                      <td>
                        {usercount > 0 && usercount}
                      </td>
                      <td>{assmcount > 0 && assmcount}</td>
                    </tr>
                  )

                })}</tbody>
            </Table>
          </div>

          {site &&
            <div style={{ marginTop: "2rem" }}>
              <h3>{site.sitedoc?.values?.name}</h3>
              <Table striped bordered hover className="stTable">
                <tbody>
                  <tr><th>Site ID:</th><td>{site.siteid}</td></tr>{
                  site.exists && <tr>
                    <th title="There is a live version of this site - archive it before restoring this archive?">Archive current site?</th>
                    <td><Form.Switch id="archexist" checked={archiveExisting!==false} onChange={()=>{ setArchiveExisting(archiveExisting===false) }} /></td>
                  </tr>
                  }<tr>
                    <th>Archive Date:</th>
                    <td>{site.sites?.length === 1
                      ? ukdate(site.when)
                      :
                      <Dropdown>
                        <Dropdown.Toggle variant="outline-secondary" title="Archive Date">
                          {ukdate(archive.when)} {dtime(archive.when)}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {
                            site.sites.map(s =>
                              <Dropdown.Item onClick={() => setArchive(s)} key={s.when}>{ukdate(s.when)} {dtime(s.when)}</Dropdown.Item>
                            )
                          }
                        </Dropdown.Menu>
                      </Dropdown>
                    }</td>
                  </tr>{archive &&
                      <>
                      <tr>
                        <th>Archive ID: </th>
                        <td>{archive._id}</td>
                      </tr>
                      <tr>
                        <th>Site Name: </th>
                        <td>{archive.sitedoc.values.name}</td>
                      </tr>
                      <tr>
                        <th>Site Address: </th>
                        <td>{archive.sitedoc.values.address}</td>
                      </tr>{
                      archive.sitedoc.values.telephone && <tr>
                        <th>Site Telephone: </th>
                        <td>{archive.sitedoc.values.telephone}</td>
                      </tr>
                      }<tr>
                        <th>Assessments: </th>
                        <td>
                        <Table striped hover>
                            <thead>
                              <tr>
                                <th>Assessment</th>
                                <th title="Restore this asssessment when site is restored?">Restore?</th>
                              </tr>
                            </thead>
                            <tbody>{(archive.assessmentdata || []).map(a =>
                                <tr key={a._id}>
                                  <td>{a.values.name} [{a.values.licence}]<br /><span className="small">{a.archiveid || `[${a._id}]`}</span></td>
                                  <td><Form.Switch id={`archrescheck_${a._id}`} checked={restoreArchive[a.archiveid]!==false} onChange={()=>setRestoreArchive(r=>({ ...r, [a.archiveid]: restoreArchive[a.archiveid]===false }))} /></td>
                                </tr>
                              )}</tbody>
                          </Table>
                        </td>
                      </tr>
                      <tr>
                        <th>Users: </th>
                        <td>
                        <Table striped hover>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Email</th>
                              <th title="This is an active user account">Live</th>
                              <th title="Re-create user account">Restore?</th>
                              <th title="Email user to inform them that they have access">Inform?</th>
                            </tr>
                          </thead>
                          <tbody>{(archive.userdata || []).map(a =>
                              <tr key={a.email}>
                                <td>{a.name}</td>
                                <td>{a.email}</td>
                                <td>{a.exists && <Icon>check</Icon>}</td>
                                <td title={a.exists?'User exists':''}><Form.Switch id={`userrescheck_${a.email}`} disabled={a.exists} checked={a.exists?false:restoreUser[a.email]!==false} onChange={()=>setRestoreUser(r=>({ ...r, [a.email]: restoreUser[a.email]===false }))} /></td>
                                <td>{restoreUser[a.email]!==false  && <Form.Switch id={`userinfcheck_${a.email}`} checked={informUser[a.email]!==false} onChange={()=>setInformUser(r=>({ ...r, [a.email]: informUser[a.email]===false }))} />}</td>
                              </tr>
                            )}</tbody>
                          </Table>
                        </td>
                      </tr>
                      </>
                }</tbody>
              </Table>
            </div>}



          {/*}
              const dt = new Date(Number(a.when)).toString();

              return (
                <InputGroup key={`archlist_${a.archiveid}`}>
                  <div className="form-control">
                    {a.name} {dt.substring(0, 24)} {a.size && `[${humanFileSize(a.size)}]`}
                  </div>
                  <Button type="button" variant="info" onClick={restoreSite(a.archiveid)}><FAIcon icon="undo" /> Restore</Button>
                </InputGroup>
              )
            })
          )*/}

        </>}

        </Modal.Body>
        <Modal.Footer>
          <Button type="button" variant="secondary" onClick={showModal}>Close</Button>
          <Button type="button" variant="primary" onClick={restoreSite}>{restoring && <Spinner as="span" animation="border" size="sm" />} Restore Site</Button>
        </Modal.Footer>
      </Modal>
    </ButtonGroup>
  )

}

export { ArchiveModal, SiteArchiveModal, RestoreSiteModal }