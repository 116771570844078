import React from 'react';
//import { StyleSheet, css } from 'aphrodite';
import { Tabs, Tab } from 'react-bootstrap';

import SettingsEmail from './email';
import SettingsIntro from './intro';
import SettingsGeneral from './general';
import SettingsPrices from './prices';

const Settings = ({ settings, updateSetting, dbFunctions, toast }) => {

  //const [emailSettings, setEmailSettings] = useState([]);

  const tablist = [
    { id: 'email', title: 'Email', content: <SettingsEmail settings={settings['settings-email']} update={updateSetting('settings-email')} dbFunctions={dbFunctions} toast={toast} /> },
    { id: 'intro', title: 'Intro', content: <SettingsIntro dbFunctions={dbFunctions} toast={toast} /> },
    { id: 'general', title: 'General', content: <SettingsGeneral settings={settings['settings-general']} update={updateSetting('settings-general')} dbFunctions={dbFunctions} toast={toast} /> },
    { id: 'prices', title: 'Prices', content: <SettingsPrices settings={settings['settings-prices']} update={updateSetting('settings-prices')} dbFunctions={dbFunctions} toast={toast} /> },
  ];

  return (
    <div style={{ marginTop: '1em' }}>
      <Tabs defaultActiveKey={tablist[0].id} id="settingstabs">
        {tablist.map(t =>
          <Tab key={t.id} eventKey={t.id} title={t.title}>
            {t.content}
          </Tab>
        )}
      </Tabs>
    </div>
  )
}

export default Settings;