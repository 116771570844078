import React, { useState, useEffect } from 'react';

import { Modal, Alert, Form, Button, ButtonGroup, Spinner, InputGroup } from 'react-bootstrap';

import { validateEmail } from '../Utils'; //validateNotBlank

import { Icon } from '../Components';

/**
 * Popup to add new Site
 * @param {*} templates 
 */
const CustomInvite = ({ dbFunctions, settings={}, toast }) => {

    const [show, setShow] = useState(false);
    const [sending, setSending] = useState(false);
  
    const [formValues, setFormValues] = useState({});
    //const [defaultVals, setDefaultVals] = useState({});
    const [formValid, setFormValid] = useState({});
  
    const [message, setMessage] = useState('');
  
    const showModal = s => {
      const ns = s===true || s===false?s:!show;
      if(ns) clear();
      setShow(ns);
    }
  
    const clear = () => {
      setFormValues({});
      setFormValid({});
      setMessage('');
    }
  
    //Set form value on edit
    const setFormVal = (name, check) => e => {
      const value = typeof e==="object"?e.target.value:e;
  
      //console.log("form val: ", value);
  
      setFormValues({...formValues, [name]:value});
  
      if(check) {
        //[].concat(check).forEach //!Run multiple checks, check for required
  
        const isValid = check(value);
  
        setFormValid({[name]:(isValid===true || isValid)});
      }
    }
  
    //Check if a field has ben set as valid
    const valid = field => formValid[field] && formValid[field]!==true ? formValid[field] : '';
  
    const sendInvite = async () => {
  
      setSending(true);
  
      //const response = await dbFunctions.dbAdminPost({ query: ['trilogy','custominvite'], doc: {email: formValues.users, name: formValues.uname, oneyear: formValues.one, threeyear: formValues.three }, toast });
      const response = await dbFunctions.dbAdminPost({ query: ['trilogy','custominvite'], doc: formValues });

      if(response) {
        if(response.success) showModal(false);
  
        toast(response.message);
      } else {
        toast(`Unknown error sending invitation email`);
      }
  
      setSending(false);
    }
  
    useEffect(()=>{
      const dv = {};
  
      dv['one']=settings["settings-general"]?.invitepricedefaultoneyear?.value;
      dv['three']=settings["settings-general"]?.invitepricedefaultthreeyears?.value;
  
      setFormValues(fv=>({...fv, ...dv}));
  
    }, [settings, show, setFormValues])
  
  
    return (
  
      <ButtonGroup>
        <Button onClick={showModal} variant="outline-secondary" title="Custom Invitation"><Icon>envelope</Icon></Button>
  
        <Modal show={show} size="lg" onHide={showModal}>
          <Modal.Header closeButton>
            <Modal.Title>Custom Invitiation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {message && <Alert variant='primary'>
              {message}
            </Alert>}
            <div>
              Use this form to send an email with a custom price to a new user of Firesmart or a user moving from legacy Firesmart.
            </div>
            <hr />
            <Form>
              <Form.Group>
                <Form.Label>User Email</Form.Label>
                <Form.Control type="email" value={formValues.email || ''} onChange={setFormVal("email", validateEmail)} />
                <Form.Text className="text-muted">{valid('email')}</Form.Text>
                {/*<input id="pw" type="password" className="form-control" onChange={sv(setPassword)} autoFocus onKeyPress={enterOK} />*/}
              </Form.Group>
              <Form.Group>
                <Form.Label>User (Actual) Name</Form.Label>
                <Form.Control as="input" value={formValues.uname || ''} onChange={setFormVal("uname")} />
                <Form.Text className="text-muted">{valid('uname')}</Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Label>1 year price (ex VAT)</Form.Label>
                <InputGroup>
                    <InputGroup.Text>£</InputGroup.Text>
                  <Form.Control as="input" type="number" value={formValues.one || ''} onChange={setFormVal("one")} step="0.01" className="money" />
                </InputGroup>
                <Form.Text className="text-muted">{valid('one')}</Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Label>3 year price (ex VAT)</Form.Label>
                <InputGroup>
                    <InputGroup.Text>£</InputGroup.Text>
                  <Form.Control as="input" type="number" value={formValues.three || ''} onChange={setFormVal("three")} step="0.01" className="money" />
                </InputGroup>
                <Form.Text className="text-muted">{valid('three')}</Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Label>Offer Expires (optional)</Form.Label>
                <Form.Control as="input" type="date" value={formValues.expires || ''} onChange={setFormVal("expires")} />
                <Form.Text className="text-muted">{valid('expires')}</Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Label>Email Note (optional)</Form.Label>
                <Form.Control as="textarea" value={formValues.note || ''} onChange={setFormVal("note")} />
              </Form.Group>
  
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" variant="primary" onClick={sendInvite}>{sending && <Spinner as="span" animation="border" size="sm" />} Send</Button>
          </Modal.Footer>
        </Modal>
      </ButtonGroup>
  
    )
  }

export default CustomInvite;