import React, { useState, useEffect } from 'react';
//import { StyleSheet, css } from 'aphrodite';
import { Button, Table, InputGroup, Spinner, Form } from 'react-bootstrap';
import { Searcher, FAIcon } from '../Components';

import orderReport from './orderReport';
import downloadCSV from './downloadCSV';
//import removeTrilogyEmails from './removeTrilogyEmails';



const MA11 = new Date();
MA11.setMonth(MA11.getMonth() - 11);

const MA12 = new Date();
MA12.setMonth(MA12.getMonth() - 12);

const MA17 = new Date();
MA17.setMonth(MA17.getMonth() - 17);

const MA18 = new Date();
MA18.setMonth(MA18.getMonth() - 18);

const MA = [
  { ago: 18, dt: MA18, col: '#ff4500' },
  { ago: 17, dt: MA17, col: '#ff8f66', dec: 'dashed' },
  { ago: 12, dt: MA12, col: '#ffbc00' },
  { ago: 11, dt: MA11, col: '#ffd766', dec: 'dashed' }
]

/**
 * Colour-codes a UK formatted date 
 * @param {*} dt 
 */
function colorCodeDates(dt) {
  if (dt) {

    const d = dt.split('/');
    if (d.length < 3) return dt;
    const jdt = new Date(d[2], d[1], d[0]);

    for (const m of MA) {
      if (jdt < m.dt) {
        return <span title={`${m.ago}+ Months Ago`} style={{ color: m.col }}>{dt}</span>
      }
    }

    return (
      <span>{dt}</span>
    )

  }

  return dt;

}

function Contactor({ children, assessmentid, data, setEmailChecked, isChecked }) {

  const contacts = children;

  return (
    contacts.map(c =>
      c?.email
        ? <Form.Check
          onChange={setEmailChecked(assessmentid, c.email)}
          checked={isChecked(assessmentid, c.email)}
          key={c.email}
          id={`${assessmentid}_${c.email}`}
          type="checkbox"
          label={c.name ? `${c.name} <${c.email}>` : c.email} />
        : ''
    )
  )
}


function LastUsed({ dbFunctions, toast }) {

  const [updating, setUpdating] = useState(false);
  const [report, setReport] = useState([]);
  const [sending, setSending] = useState(false);

  const [reportHeader, setReportHeader] = useState([]);
  const [reportObj, setReportObj] = useState([]);
  const [reportSearch, setReportSearch] = useState([]);
  const [orderBy, setOrderBy] = useState({});

  const [checked, setChecked] = useState({});

  const [emailList, setEmailList] = useState([]);

  const reportToObj = (report, headers) => report.map(r => Object.assign(...r.map((c, ci) => ({ [headers[ci]]: c }))))


  //const evcompare = (v1, v2) => v1.useremail===v2.useremail && v1.siteid===v2.siteid && v1.assessmentid === v2.assessmentid;

  //const isChecked = ({email, siteid, assid}) => emailList.find(v=>email===v.email && siteid===v.siteid && assid === v.assid);


  const setEmailChecked = (assessmentid, email) => e => {

    setChecked(c => ({ ...c, [`${assessmentid}_${email}`]: !c[`${assessmentid}_${email}`] }));

    /*console.log("setEmailChecked", e, data);
  	
    const checked = e?.target?.checked;

    setEmailList(l=>{
      if(!checked) return l.filter(v=>!evcompare(v, data))
      else {
        if(l.find(v=>evcompare(v, data))) return l;
        else return l.concat(data);
      }
    })*/

    //console.log("setEmailChecked: ", e?.target?.checked, email, siteid, assid);

  }

  const isChecked = (assessmentid, email) => !!checked[`${assessmentid}_${email}`];

  /**
   * Get a list of data for users that have been ticked in the report
   */
  const getEmailList = () => {

    const reports =
      reportSearch.reduce((a, r) => {

        const assessmentid = r.assid;
        const contacts = r['Contacts'];

        var out = [];

        contacts.forEach(c => {

          if (isChecked(assessmentid, c.email)) {

            const siteid = r.siteid;
            //const sitename = Object.values(r)[1];
            const sitename = r['Site Name']
            const assessmentname = r['Assessment Name'];

            const data = { siteid, assessmentid, sitename, assessmentname, email: c.email, useremail: c.email, useractualname: c.name };

            out.push(data);
          }
        })

        if (out.length) return a.concat(out);
        return a;

      }, [])

    return reports;
  }

  useEffect(() => {
    if (report.length) {

      const headers = report[0];
      const body = report.slice(1);
      //setReportOrdered(report.slice(1));

      const ro = reportToObj(body, headers);

      setReportHeader(headers);
      setReportObj(ro);
      setReportSearch(ro);

    } else {
      setReportHeader([]);
      setReportSearch([]);
      setReportObj([]);
      setOrderBy({});
      setEmailList([]);
    }

    setOrderBy({});

  }, [report]);

  /*const fieldbytitle = (row, title) => {
	
    console.log("fbt: ", row, title, reportHeader.findIndex(v=>v===title));

    return row[reportHeader.findIndex(v=>v===title)];
  }*/


  //Get report from back end
  const getReport = async () => {
    setUpdating(true);

    console.log("Updating Last Used report...");

    const report = await dbFunctions.dbAdminPost({ query: ['trilogy', 'tglastusedreport'], doc: { includeids: true } });

    console.log("data: ", report);

    if (report.success) {
      setReport(report.data);
    }
    else {
      toast(report.message || 'Report updated');
    }

    setUpdating(false);
  }

  const setOrder = fieldno => () => {

    if (fieldno === false) setOrderBy({});
    else {
      const direction = orderBy?.fieldno === fieldno && orderBy?.direction ? -(orderBy.direction) : 1;

      setOrderBy({ fieldno, direction });
    }
  }

  /**
   * Takes a bunch of emails
   * @param {*} emails 
   */
  const oneEmailPerAddress = (emails = []) => {

    const out = emails.reduce((arr, el) => {

      const emf = arr.findIndex(a => a.useremail === el.useremail);

      if (emf > -1) {

        const r = arr[emf];

        const newemf = ({
          useremail: r.useremail,
          useractualname: r.useractualname || el.useractualname,
          multifields: (
            r.multifields ||
            [{ assessmentid: r.assessmentid, assessmentname: r.assessmentname, siteid: r.siteid, sitename: r.sitename }]
          ).concat({ assessmentid: el.assessmentid, assessmentname: el.assessmentname, siteid: el.siteid, sitename: el.sitename })
        })

        arr[emf] = newemf;

        return arr;

      } else {
        return arr.concat(el);
      }

      //return arr;

    }, [])

    return out;
  }

  const sendEmails = async () => {
    setSending(true);

    /*const fields = {
      useremail: user.username,
      cc,
      useractualname: user.actualname || (user.doc).fullname || '',
      note
    }*/


    const emailList = getEmailList();

    const emails = oneEmailPerAddress(emailList);

    console.log("emails: ", emails, emailList);


    const type = 'timetoassessreminder';

    //data = [{ email, cc, fields, multiple }]
    ///multiple = sitename, assessmentname

    /*const data = emailList.reduce((a, el)=>{
      const emf = a.findIndex(a=>a.useremail===el.useremail);

      //console.log("reduce: ", emf, a, el);

      if(emf>-1) {

        const r = a[emf];

        const newemf =  ({
          useremail: r.useremail,
          useractualname: r.useractualname || el.useractualname,
          multifields: (
            r.multifields || 
            [{assessmentid: r.assessmentid, assessmentname: r.assessmentname, siteid: r.siteid, sitename: r.sitename}]
          ).concat({ assessmentid: el.assessmentid, assessmentname: el.assessmentname, siteid: el.siteid, sitename: el.sitename })
        })

        console.log("newemf: ", newemf);

        //const emo = [].concat( a.slice(0, r), newemf, a.slice(r+2) );

        //console.log("emo: ", emo);

        //return emo;

        return a.concat(el);

      } else {
        return a.concat(el);
      }

    } ,[]);*/

    var response;
    response = await dbFunctions.dbAdminPost({ query: ['trilogy','sendemails'], doc: {data: emails, type} });

    if (response) {

      toast(response.err ? `${response.message}: ${response.err.message}` : response.message);
      console.log("email send response: ", response);
    } else {
      toast(`Unknown error sending email`);
    }

    setSending(false);

  }


  return (
    <>
      <br />
      <InputGroup>
          <Button variant="success" onClick={getReport}>{updating && <Spinner as="span" animation="border" size="sm" />} {report.length > 0 ? 'Update' : 'Get'} Report</Button>
          {report.length > 0 &&
            <>
              <Button variant="danger" onClick={() => setReport([])}>Clear</Button>
              <Button variant="info" onClick={downloadCSV(report, "LastUsed")}>Download CSV</Button>
              <Button variant="warning" onClick={sendEmails} title={emailList?.length ? 'Click to send reminder to complete assessment' : 'Please select some contacts'}>
                {sending ? <Spinner as="span" animation="border" size="sm" /> : <FAIcon icon="envelope-o" />}
                Send Time to Assess Emails</Button>
            </>
          }
      </InputGroup>

      <Form>
        <Table striped bordered hover>
          <thead className="stickyHead">
            {reportObj?.length > 0 && <tr>
              <th colSpan={'100%'} style={{ padding: 0 }}>
                <Searcher
                  keys={reportHeader}
                  values={reportObj}
                  onChange={setReportSearch}
                >
                </Searcher>
              </th>
            </tr>}
            <tr>
              {reportHeader.map((r, k) =>
                k < 10 ? <th key={k}>
                  <button type="button" title={`Order by ${r}`} className="plain left" onClick={setOrder(k)} onDoubleClick={setOrder(false)} >
                    {r}
                    {orderBy?.fieldno === k ? ` ${orderBy.direction === 1 ? '▼' : '▲'}` : ''}
                  </button>
                </th>
                  : null
              ).filter(v => v)
              }
            </tr>
          </thead>
          <tbody>
            {orderReport(reportSearch, orderBy).map((r, k) =>
              <tr key={k}>{Object.values(r)?.map((c, k) => {

                if (!(reportHeader[k] && k < 10)) return null

                var out;

                if ([4, 5, 6].includes(k)) {
                  out = colorCodeDates(c);
                }
                else if (k === 9) {

                  //const siteid = Object.values(r)[10];
                  const siteid = r.siteid;
                  const assessmentid = r.assid;
                  //const sitename = Object.values(r)[1];
                  const sitename = r['Site Name']
                  const assessmentname = r['Assessment Name'];

                  const data = { siteid, assessmentid, sitename, assessmentname };

                  out = <Contactor assessmentid={assessmentid} setEmailChecked={setEmailChecked} data={data} isChecked={isChecked} >{c}</Contactor>
                }
                else out = c;

                return <td key={k}>{out}</td>

              })}</tr>

            )
            }
          </tbody>
        </Table>
      </Form>
    </>
  )

}

export default LastUsed;