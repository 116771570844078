import React, { useState } from 'react';

import { Modal, Alert, Form, Button, ButtonGroup, Spinner, Dropdown, InputGroup } from 'react-bootstrap';
//import { Document, Page } from 'react-pdf';
//import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import { validateEmail } from '../Utils'; //validateNotBlank
import { FAIcon } from '../Components';

//import SiteUser from './siteuser';

const cuid = require('cuid');


/**
 * Popup to add new Site
 * @param {*} templates 
 */
 const AddSite = ({ templates, dbFunctions, updateSites, toast, className, style }) => {

    const [show, setShow] = useState(false);
  
    const [formValues, setFormValues] = useState({});
    const [formValid, setFormValid] = useState({});
    const [siteid, setSiteId] = useState(false);
  
    const [addingSite, setAddingSite] = useState(false);
    const [message, setMessage] = useState('');
  
    const showModal = s => {
      const ns = s === true || s === false ? s : !show;
      clear();
  
      if (ns) setSiteId(cuid());
  
      setShow(ns);
    }
  
    const clear = () => {
      const defaults = {};
  
      defaults.licencelength = 1;
  
      //Default template(?)
      const dt = (templates.find(t => t.default) || {})._id;
      if (dt) defaults.template = dt;
  
      setFormValues(defaults);
      setFormValid({});
      setMessage('');
    }
  
    //Set form value on edit
    const setFormVal = (name, check) => e => {
      const value = typeof e === "object" ? e.target.value : e;
  
      setFormValues({ ...formValues, [name]: value });
  
      if (check) {
        //[].concat(check).forEach //!Run multiple checks, check for required
  
        const isValid = check(value);
  
        setFormValid({ ...formValid, [name]: (isValid === true || isValid) });
      }
    }
  
    //Check if a field has ben set as valid
    const valid = field => formValid[field] && formValid[field] !== true ? formValid[field] : '';
  
    const addSite = async () => {
  
      setAddingSite(true);
      setMessage('');
  
      try {
        const doc = { ...formValues, siteid };
        var ap = await dbFunctions.dbAdminPost({ query: ['sites', 'tgcreate'], doc });
      } catch (err) {
        toast(`Error adding site: ${err}`);
      }
  
      setAddingSite(false);
  
      //getDbs();
      if (ap) {
        if (ap.success) {
          toast(`Site added!`)
          updateSites();
          setShow(false);
        }
        else {
          toast(`Error adding site: ${ap.message}`);
          setMessage(ap.message);
        }
      }
      else {
        toast(`Error adding site`);
      }
  
      return ap;
    }
  
    //const enterOK = e => e && e.key === 'Enter' && tryAddSite();
  
    //const sv = f => e => f(e.target.value);
  
    //Format template for display
    const templateformat = (template) => (
      <div>
        <div>{template.name}</div>
        <div>{template.customer ? `[${template.customer}] ` : ''}[{new Date(template.when).toString().substring(0, 24)}]</div>
      </div>
    )
  
    return (
  
      <ButtonGroup className={className} style={style}>
  
        <Button title="Add Site" onClick={showModal} variant="outline-secondary"><FAIcon icon="plus-circle"/></Button>
  
        <Modal show={show} size="lg" onHide={showModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add Site</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {message && <Alert variant='primary'>
              {message}
            </Alert>}
            <Form>
              <Form.Group>
                <Form.Switch id={'addUserCheck'} checked={formValues.addUser !== false} onChange={() => setFormVal('addUser')(!(formValues.addUser !== false))} label={'Add User'} />
              </Form.Group>
              {formValues.addUser !== false &&
                <>
                  <Form.Group>
                    <Form.Label>User Email</Form.Label>
                    <InputGroup>
                      <Form.Control type="email" value={formValues.users || ''} onChange={setFormVal("users", validateEmail)} autoComplete="new-password" />
                      <Button variant="outline-secondary" onClick={() => setFormVal('super')(!formValues.super)} >Super: <FAIcon icon={formValues.super ? 'check' : 'times'} /></Button>
                    </InputGroup>
                    <Form.Text className="text-muted">{valid('users')}</Form.Text>
                    {/*<input id="pw" type="password" className="form-control" onChange={sv(setPassword)} autoFocus onKeyPress={enterOK} />*/}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>User (Actual) Name</Form.Label>
                    <Form.Control as="input" value={formValues.uname || ''} onChange={setFormVal("uname")} autoComplete="new-password" />
                    <Form.Text className="text-muted">{valid('uname')}</Form.Text>
                  </Form.Group>
                </>
              }
              <Form.Group>
                <Form.Label>Site Name</Form.Label>
                <Form.Control as="input" value={formValues.name || ''} onChange={setFormVal("name")} autoComplete="new-password" />
                <Form.Text className="text-muted">{valid('name')}</Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Label>Site Address</Form.Label>
                <Form.Control as="textarea" rows="5" value={formValues.address || ''} onChange={setFormVal("address")} autoComplete="new-password" />
                <Form.Text className="text-muted">{valid('address')}</Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Label>Site Contact Number</Form.Label>
                <Form.Control as="input" type="tel" value={formValues.telephone || ''} onChange={setFormVal("telephone")} autoComplete="new-password" />
                <Form.Text className="text-muted">{valid('telephone')}</Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Label>Assessment Template</Form.Label>
                <Dropdown>
                  <Dropdown.Toggle variant="outline-secondary">
                    {formValues && formValues.template ? templateformat(templates.find(t => t._id === formValues.template) || {}) : 'Template'}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {templates.sort((a, b) => a.when > b.when ? -1 : 1).map(t =>
                      <Dropdown.Item onClick={() => setFormVal("template")(t._id)} key={t._id}>{templateformat(t)}</Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
              <Form.Group>
                <Form.Label>Licence Duration (Years)</Form.Label>
                <InputGroup>
                  <Form.Control as="input" type="number" value={formValues.licencelength || ''} onChange={setFormVal("licencelength")} autoComplete="new-password" />
                  <Button variant="outline-secondary" active={Number(formValues.licencelength) === 1} onClick={() => setFormVal('licencelength')(1)} >1 Year</Button>
                  <Button variant="outline-secondary" active={Number(formValues.licencelength) === 3} onClick={() => setFormVal('licencelength')(3)} >3 Years</Button>
                </InputGroup>
                <Form.Text className="text-muted">{valid('licencelength')}</Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Label>Or: Custom expiry date</Form.Label>
                <Form.Control as="input" type="date" value={formValues.expiry || ''} onChange={setFormVal("expiry")} autoComplete="new-password" />
                <Form.Text className="text-muted">{valid('expiry')}</Form.Text>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" variant="primary" onClick={addSite}>{addingSite && <Spinner as="span" animation="border" size="sm" />} Add</Button>
          </Modal.Footer>
        </Modal>
      </ButtonGroup>
  
    )
  }

export default AddSite;