import React, { useState } from 'react';

import { Modal, Form, Button, ButtonGroup, Spinner, Dropdown } from 'react-bootstrap';

import { FAIcon } from '../Components';

/**
 * Popup to upgrade Assessment
 * @param {*} templates 
 */
 const UpgradeAssessmentModal = ({ siteid, sitename, surveyid, AssessmentName, templates, dbFunctions, updateSite, toast }) => {

    const [show, setShow] = useState(false);
  
    const [formValues, setFormValues] = useState({});
    const [formValid, setFormValid] = useState({});
    //const [siteid, setSiteId] = useState(false);
  
    const [updatingAssessment, setUpdatingAssessment] = useState(false);
    //const [message, setMessage] = useState('');
  
    const showModal = s => {
      const ns = s === true || s === false ? s : !show;
      clear();
  
      //if (ns) setSiteId(cuid());
  
      setShow(ns);
    }

  
    const clear = () => {
      const defaults = {};
  
      //Default template(?)
      const dt = ((templates || []).find(t => t.default) || {})._id;
      if (dt) defaults.templateid = dt;
  
      setFormValues(defaults);
      setFormValid({});
    }
  
    //Set form value on edit
    const setFormVal = (name, check) => e => {
      const value = typeof e === "object" ? e.target.value : e;
  
      setFormValues({ ...formValues, [name]: value });
  
      if (check) {
        //[].concat(check).forEach //!Run multiple checks, check for required
  
        const isValid = check(value);
  
        setFormValid({ ...formValid, [name]: (isValid === true || isValid) });
      }
    }
  
    //Check if a field has ben set as valid
    //const valid = field => formValid[field] && formValid[field] !== true ? formValid[field] : '';
  
    const upgradeAssessment = async () => {
  
      setUpdatingAssessment(true);
  
      try {
        //const doc = { ...formValues, siteid };
        //var ap = await dbFunctions.dbAdminPost({ query: ['sites', 'tgaddassessment'], doc });
        var ap = await dbFunctions.dbAdminPost({ query: ['trilogy', 'upgradeAssessment'], doc: { siteid, surveyid, ...formValues } });

      } catch (err) {
        toast(`Error adding assessment: ${err}`);
      }
  
      setUpdatingAssessment(false);
  
      //getDbs();
      if (ap) {
        if (ap.success) {
          toast(`Assessment upgraded!`)
          updateSite();
          setShow(false);
        }
        else {
          toast(`Error upgrading assessment: ${ap.message}`);
        }
      }
      else {
        toast(`Error upgrading assessment`);
      }
  
      return ap;
    }
  
    //const enterOK = e => e && e.key === 'Enter' && tryAddSite();
  
    //const sv = f => e => f(e.target.value);
  
    //Format template for display
    const templateformat = (template) => (
      <div>
        <div style={{fontWeight: "bold"}}>{template.name} {template.default?` [DEFAULT]`:''}</div>
        <div style={{fontStyle: 'italic'}}>{template.description}</div>
        <div className="small">{template.customer ? `[${template.customer}] ` : ''}[{new Date(template.when).toString().substring(0, 24)}] [{template._id}]</div>
      </div>
    )

    return (
  
      <ButtonGroup>
  
        <Button title="Upgrade Assessment" onClick={showModal} variant="outline-secondary"><FAIcon icon="arrow-circle-o-up" /></Button>
  
        <Modal show={show} size="lg" onHide={showModal}>
          <Modal.Header closeButton>
            <Modal.Title>Upgrade Assessment: "{sitename}" &gt; "{AssessmentName}"</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Form>
              <Form.Group>
                <Form.Label>Assessment Template</Form.Label>
                <Dropdown>
                  <Dropdown.Toggle variant="outline-secondary">
                    {formValues && formValues.templateid ? templateformat((templates || []).find(t => t._id === formValues.templateid) || {}) : 'Template'}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {(templates || []).sort((a, b) => a.when > b.when ? -1 : 1).map(t =>
                      <Dropdown.Item onClick={() => setFormVal("templateid")(t._id)} key={t._id}>{templateformat(t)}</Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" variant="primary" onClick={upgradeAssessment}>{updatingAssessment && <Spinner as="span" animation="border" size="sm" />} Upgrade</Button>
          </Modal.Footer>
        </Modal>
      </ButtonGroup>
  
    )
  }

  export { UpgradeAssessmentModal }