import React from 'react';
import { InputGroup, Button } from 'react-bootstrap';
import { FAIcon } from '.';

const Paginator = ({ page=0, setPage, numSites=0, siteCount=0 }) => {
    if(siteCount<=numSites) return null;
  
    const movePage = p => e => setPage(p);
  
    const pcount = Array.from(Array((siteCount/numSites | 0)+1));
  
    return (
      <InputGroup style={{width: 'auto', maxWidth: `${5+pcount.length*4}em`, float: 'right'}}>
          {page>1 && <Button title="Previous Page" variant="outline-secondary" onClick={movePage(0)}><FAIcon icon="backward" /></Button>}
          {page>0 && <Button title="Previous Page" variant="outline-secondary" onClick={movePage(page-1)}><FAIcon icon="chevron-left" /></Button>}
        <div className="form-control" style={{fontSize: '1.5em', lineHeight: '1em', height: 'auto', padding: '0.1em 0.5em'}}>
          {pcount.map((i,p)=><span key={p}>{p===page?`[${p+1}]`:<Button title={`Page ${p+1}`} style={{fontSize: '1em'}} onClick={movePage(p)} variant="link">[{p+1}]</Button>}</span>)}
        </div>
          {((page+1)*numSites)<siteCount && <Button title="Next Page" variant="outline-secondary" onClick={movePage(page+1)}><FAIcon icon="chevron-right" /></Button>}
      </InputGroup>
    )
}

export default Paginator;