const generaloptions = [
  {
    id: 'invitepricedefaultoneyear',
    title: 'Custom Invite Email Default 1 year price (ex VAT)',
    description: '',
    type: 'number',
    prepend: '£',
    append: ''
  },
  {
    id: 'invitepricedefaultthreeyears',
    title: 'Custom Invite Email Default 3 years price (ex VAT)',
    description: '',
    type: 'number',
    prepend: '£',
    append: ''
  }
];

export default generaloptions;