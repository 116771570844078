import React, { useState, useEffect, useMemo } from 'react';
import { Alert, InputGroup, Dropdown, Button, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Fuse from 'fuse.js';
//import nanomemoize from 'nano-memoize';
//import { dequal } from 'dequal';
import { DebounceInput } from 'react-debounce-input'

import FAIcon from './Library/FAIcon';

//!Make hideable - X should execute hide
const TopMessage = ({ children, hide }) => {

  if (!children) return null;

  return (
    <Alert >
      {children}
    </Alert>
  )

}

//const FAIcon = ({ icon, children }) => <span className={`fa fa-${icon || children || 'sqaure-o'}`} />

const Icon = ({ icon, overlay, title, children, style }) => {

  const i = `fa-${icon || children || 'square-o'}`;

  return (
    overlay
      ? <span className="fa-stack" style={style} title={title}>
        <span className={`fa ${i} fa-stack-2x`} />
        <span className={`fa fa-${overlay} fa-stack-1x`} />
      </span>
      : <span className={`fa ${i}`} style={style} title={title} />
  )
}

const CheckButton = ({ icon = 'trash', overlayIcon = '', variant = 'outline-secondary', check = 'Sure?', onClick = () => { }, title, spinner, children }) => (
  <Dropdown className="btn-group">
    <Dropdown.Toggle variant={variant} title={title}>
      { spinner ? <Spinner as="span" animation="border" size="sm" /> : <><FAIcon icon={icon} overlayIcon={overlayIcon} /> {children}</> }
    </Dropdown.Toggle>
    <Dropdown.Menu>
      <Dropdown.Item onClick={onClick} as="button">
        {check}
      </Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
)

/*
const mfuse = nanomemoize((vals, keys) => {

  return new Fuse(
    vals,
    {
      ...(keys ? { keys } : {}),
      shouldSort: true,
      threshold: 0.25,
      distance: 100,
      includeScore: true
    }
  )
}, { equals: dequal });
*/

/*
const Searcher = ({ children, values, onChange, keys = ['value'], className, style }) => {
  const [search, setSearch] = useState('');
  const [found, setFound] = useState(false);

  const vals = useMemo(() => values, [values]);

  const fuse = mfuse(vals, keys);

  const change = v => setSearch(v.target.value);

  useEffect(() => {

    const doSearch = search => fuse.search(search);

    if (search === '') {
      onChange(vals);
      setFound(false);
    }
    else if (search) {
      const searchValues = doSearch(search).map(v => ({ ...v.item, score: v.score }));
      onChange(searchValues);
      setFound(searchValues.length);
    }
  }, [vals, search, onChange, fuse])


  return (
    <InputGroup className={className} style={style}>
      <div role="group" className="btn-group">
        <Button title="Clear" onClick={() => setSearch('')} variant="outline-secondary" >🔍</Button>
      </div>
      <FormControl aria-label="Search" placeholder="Search" value={search} onChange={change} />
      <div role="group" className="btn-group">
        <Button title="Clear" onClick={() => setSearch('')} variant="outline-secondary" >🗑️</Button>
      </div>
      {found !== false && <InputGroup.Text>Found: {found}</InputGroup.Text>}
      {children}
    </InputGroup>
  )
}
*/

const Searcher = ({ children, values, onChange, keys = ['value'], className, style }) => {
  const [search, setSearch] = useState('');
  const [found, setFound] = useState(false);

  const vals = useMemo(() => values, [values]);

  //const fuse = mfuse(vals, keys);

  const fuse = useMemo(()=>new Fuse(
    vals,
    {
      ...(keys ? { keys } : {}),
      shouldSort: true,
      threshold: 0.25,
      distance: 100,
      includeScore: true
    }),
    [vals, keys]
  );

  //const onCh = useCallback(vals=>{onChange(vals)}, []);

  const change = v => {
    const val = typeof v==="object"?v.target.value:v;
    setSearch(val);
  }

  useEffect(()=> {

    if(search === '') {
      onChange(vals);
      setFound(false);
    }
    else if(search) {
      const doSearch = search => fuse.search(search);

      const searchValues = doSearch(search).map(v => ({ ...v.item, score: v.score }));
      onChange(searchValues);
      setFound(searchValues.length);
    }

  }, [search, vals])

  /*useEffect(() => {

    const doSearch = search => fuse.search(search);

    if (search === '') {
      onCh(vals);
      setFound(false);
    }
    else if (search) {
      const searchValues = doSearch(search).map(v => ({ ...v.item, score: v.score }));
      onCh(searchValues);
      setFound(searchValues.length);
    }
  }, [vals, search])
  */


  return (
    <InputGroup className={className} style={style}>
      <div role="group" className="btn-group">
        <Button title="Clear" onClick={() => change('')} variant="outline-secondary" >🔍</Button>
      </div>
      {/*<FormControl aria-label="Search" placeholder="Search" value={search} onChange={change} />*/}
      <DebounceInput
        className="form-control"
        placeholder="Search"
        minLength={1}
        debounceTimeout={250}
        onChange={change}
        value={search}
      />
      <div role="group" className="btn-group">
        <Button title="Clear" onClick={() => change('')} variant="outline-secondary" >🗑️</Button>
      </div>
      {found !== false && <InputGroup.Text>Found: {found}</InputGroup.Text>}
      {children}
    </InputGroup>
  )
}

const Logo = () => {

  const ball = {
    display: 'inline-block',
    width: '2.8em',
    height: '2.8em',
    borderRadius: '50%',
    backgroundColor: 'rgb(136,8,8)',
    background: 'linear-gradient(327deg, rgba(136,8,8,1) 0%, rgba(209,11,11,1) 58%, rgba(255,255,255,1) 95%)',
    color: '#fff'
  }

  return (
    <div style={{ display: 'inline-block', verticalAlign: 'top', fontSize: '0.3em', padding: '0.4em', transition: 'all 0.3s' }}>
      <span style={{ fontFamily: "'Noto Sans JP'", lineHeight: '2.6em', color: '#222' }}>fire<span style={ball}>smart</span></span>
    </div>
  )
}

const Logo2 = () => {

  return (
    <div style={{ display: 'inline-block', verticalAlign: 'top', padding: '0.4em', fontSize: '0.3em', transition: 'all 0.3s' }}>
      <span style={{ display: 'flex', fontFamily: 'Noto Sans JP', lineHeight: '2.6em', color: '#222' }}>
        <span style={{ display: 'inline-flex', alignItems: 'center', fontSize: '0.7em', lineHeight: '1em', textAlign: 'center', height: '4em' }}>health &amp;<br />safety</span>
        <span style={{ display: 'inline-block', width: '2.8em', height: '2.8em', borderRadius: '50%', backgroundColor: 'rgb(8,136,8)', background: 'linear-gradient(327deg, rgba(8,136,8,1) 0%, rgba(11,209,11,1) 58%, rgba(255,255,255,1) 95%)', color: '#fff' }}>smart</span>
      </span>
    </div>
  )
}

/**
 * Dismissable Alert
 */
const DisAlert = ({ variant, heading, children, icon, noicon = false }) => {
  const [show, setShow] = useState(true);

  const hide = () => setShow(false);

  return show
    ? (
      <Alert variant={variant || 'danger'} dismissible onClick={hide}>
        {heading && <Alert.Heading>{heading}</Alert.Heading>}
        {!noicon && <FAIcon icon={icon || 'exclamation-triangle'} />} {children}
      </Alert>
    )
    : null;
}


const HoverTip = ({ children, tip, info=false }) => {
  
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip>
            {tip}
          </Tooltip>
        }
      >
        {info
          ?<Icon>info-circle</Icon>
          :{children}
        }
      </OverlayTrigger>
    )
  }


export {
  TopMessage,
  FAIcon,
  Icon,
  CheckButton,
  Searcher,
  Logo,
  Logo2,
  DisAlert,
  HoverTip
}