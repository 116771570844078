import React, { useState } from 'react';

import { Alert, Modal, Button, Form, Spinner } from 'react-bootstrap';

//const cuid = require('cuid');

/*let subsite = 'alpha';

if(window.location.hostname.includes('alpha')) subsite='alpha';
else if(window.location.hostname.includes('beta')) subsite='beta';*/
//else subsite='';

const dbserver = process.env.REACT_APP_DB_SERVER;

/**
 * Attempt Login
 * @param {*} user email 
 * @param {*} user password 
 */
const login = async (email, password) => {

  try {
    //var l = await fetch(`https://${['db', subsite].join('.')}.firesmart.co.uk/_session`, {

      var l = await fetch(`${dbserver}/_session`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'content-type': 'application/json',
        //'withCredentials': true,
        //'authorization': `Basic ${btoa(`${email}:${password}`)}`
      },
      //body: {}
      body: JSON.stringify({ name: email, password })
      })

  } catch (err) {
    console.log("error: ", err);
  }

  return l;
}

const logout = async (success) => {

  try {
    //var l = await fetch(`https://${['db', subsite].join('.')}.firesmart.co.uk/_session`, {
      var l = await fetch(`${dbserver}/_session`, {
      method: 'DELETE',
      mode: 'cors',
      //credentials: 'include',
      headers: {
        'content-type': 'application/json'
      }
    })
  } catch (err) {
    console.log("error: ", err);
  }

  //console.log("Logout reponse: ", l);

  if (l?.ok) await success();
}


/**
 * Login Modal
 * @param {*} param0 
 */
const Login = ({ success }) => {

  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [loginmessage, setLoginmessage] = useState('');
  const [loggingIn, setLoggingIn] = useState(false);

  const sv = f => e => f(e.target.value);

  const trylogin = async () => {
    setLoginmessage('');
    setLoggingIn(true);
    const ar = await login(username, password);

    const lrec = await ar.json();

    const a = lrec && !!lrec?.roles?.find(r=>r==='_admin');

    setLoggingIn(false);

    if(ar && !a) {
      setLoginmessage('Unauthorised user');
    }
    else if(!ar) {
      setLoginmessage('General login error');
    }
    else if (ar.ok) {
      success({username, password});
    }
    else {
      if (ar.status === 401) {
        setLoginmessage('Unauthorised - incorrect username and/or password');
      }
      else {
        setLoginmessage(`Login failure - status ${ar.status} - please try again later`)
      }
    }

  }

  const enterOK = e => e && e.key === 'Enter' && trylogin();

  return (

    <Modal show={true} size="lg" onHide={() => { }}>
      <Modal.Header>
        <Modal.Title>Log In</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loginmessage && <Alert variant='primary'>
          {loginmessage}
        </Alert>}
        <Form>
          <Form.Group>
            <Form.Label>Username</Form.Label>
            <input id="username" className="form-control" onChange={sv(setUsername)} autoFocus />
          </Form.Group>
          <Form.Group>
            <Form.Label>Password</Form.Label>
            <input id="pw" type="password" className="form-control" onChange={sv(setPassword)} autoFocus onKeyPress={enterOK} />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" variant="primary" onClick={trylogin}>{loggingIn && <Spinner as="span" animation="border" size="sm" />} Log In</Button>
      </Modal.Footer>
    </Modal>

  )
}

export {
  Login,
  logout
}