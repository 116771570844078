import React, { useState, useEffect, useMemo } from 'react';

import { Table, Modal, Alert, Form, Button, ButtonGroup, Spinner, Dropdown } from 'react-bootstrap';
//import { Document, Page } from 'react-pdf';
//import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import { ukdate } from '../Utils'; //validateNotBlank
import { Searcher, FAIcon } from '../Components';
import { Paginator } from '../Library';

import CustomInvite from './CustomInvite';
//import Licence from './Licence';
import Sharing from './Sharing';
//import Attachments from './Attachments';
//import Reports from './Reports';
import AddSite from './AddSite';
import { SiteArchiveModal, RestoreSiteModal } from './Archive';
import { Assessment } from './Assessment';
import { AddAssessmentModal } from './AddAssessment';

//import SiteUser from './siteuser';

//const cuid = require('cuid');

//const adminserver = process.env.REACT_APP_ADMIN_SERVER;

/**
 * Tab - list of Sites
 */
const Sites = ({ templates, siteList = [], siteArchives=[], setSiteArchives, getSiteArchives, updateSites, updateUsers, updateSite, sitesUpdating, dbFunctions, userList = [], settings = {}, toast }) => {
  //const [message, setMessage] = useState('');
  //const [siteList, setSiteList] = useState([]);
  const [siteListDefault, setSiteListDefault] = useState([]); //Default sitelist, ordered in default order
  const [sitesFiltered, setSitesFiltered] = useState(siteList);
  //const [addingSite, setAddingSite] = useState(false);
  const [siteArchivesBySite, setSiteArchivesBySite] = useState({}); //Site archives by site ID (object)

  const [appWindowId, setAppWindowId] = useState(false); //Id of opened window
  const [numSites, setNumSites] = useState(20);
  const [page, setPage] = useState(0);

  const deleteSite = siteid => async contactlist => {

    //!Do some sort of popup message thing

    const doDelete = await dbFunctions.dbAdminPost({ query: ['sites', 'tgdeletesite'], doc: { siteid, contactlist } })

    if (doDelete && doDelete.success) {
      //updateSites();
      updateSite(siteid);
    }

    return doDelete;
  }

  const updateThisSite = siteid => () => {
    updateSite(siteid);
  }

  /*const setSiteList = sites => {
    setPage(0);
    setSitesFiltered(sites);
  }*/

  //const thisSiteArchive = siteid => siteArchives?.length ? [] : [];

  //console.log("Sites siteArchive: ", siteArchives);

  const sl = useMemo(() => siteList, [siteList]);

  //Set default list of filtered Sites when siteList updates
  useEffect(() => { setSiteListDefault((sl || []).sort((a, b) => (a.site || {}).when < (b.site || {}).when ? 1 : -1)) }, [sl]);

  //useEffect(() => { getDbs() }, []);

  useEffect(() => setPage(0), [sitesFiltered]);

  //Create object of Site Archives for quick access by Site ID
  useEffect(() => setSiteArchivesBySite( siteArchives.reduce((a,c)=>{a[c.siteid]=c; return a}, {}) || {} ), [siteArchives]);
  
  return (

    <div>
      <button className="plain" onClick={updateSites}><h2>Sites [{(sl || []).length}] {sitesUpdating && <Spinner animation="border" />}</h2></button>

      <Table striped bordered hover className="stTable">
        <thead className="stickyHead">
          <tr>
            <th colSpan={5} style={{ padding: 0 }}>
              <Searcher
                values={siteListDefault}
                onChange={setSitesFiltered}
                keys={[
                  'value',
                  'site.user',
                  'site.values.name',
                  'site.values.address',
                  'site.values.telephone',
                  'security.members.names',
                  'assessments.values.name',
                  'assessments.values.licence',
                  'assessments.latestreport.whenuk',
                  'userdetails.actualname',
                  'userdetails.doc.fullname'
                ]}
              >
                <Dropdown>
                  <Dropdown.Toggle variant="outline-secondary" title="Sites/page">
                    {numSites || 'All'}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {
                      [10, 20, 40, 100, 'All'].map(n => <Dropdown.Item onClick={() => setNumSites(n === 'All' ? null : n)} key={n}>{n}</Dropdown.Item>)
                    }
                  </Dropdown.Menu>
                </Dropdown>
                <AddSite templates={templates} dbFunctions={dbFunctions} updateSites={updateSites} toast={toast} />
                <RestoreSiteModal siteArchives={siteArchives} setSiteArchives={setSiteArchives} getSiteArchives={getSiteArchives} dbFunctions={dbFunctions} updateSites={updateSites} updateSite={updateSite} toast={toast} />
                <CustomInvite dbFunctions={dbFunctions} settings={settings} toast={toast} />
              </Searcher>
            </th>
          </tr>
          <tr>
            {/*<th>Creator</th>*/}
            <th>Site Name</th>
            <th>Site Address</th>
            <th>Users</th>
            <th>
              <div>Assessments</div>
              {/*<div className="small">Last Updated</div>
              <div className="small">Licence</div>*/}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {(sitesFiltered
            ? numSites
              ? sitesFiltered?.slice((page * numSites), numSites + page * numSites)
              : sitesFiltered
            : []
          ).map(site =>
            <Site
              key={site.id}
              site={site}
              userList={userList}
              deleteSite={deleteSite(site.id)}
              dbFunctions={dbFunctions}
              updateSite={updateThisSite(site.id)}
              updateUsers={updateUsers}
              getSiteArchives={getSiteArchives}
              appWindowId={appWindowId}
              setAppWindowId={setAppWindowId}
              siteArchive={siteArchivesBySite[site.id]}
              templates={templates}
              toast={toast} />
          )}
        </tbody>
      </Table>
      <Paginator page={page} setPage={setPage} numSites={numSites} siteCount={(sitesFiltered || []).length} />
    </div>
  )
}

const Site = ({ site, userList, deleteSite, updateSite, updateUsers, dbFunctions, appWindowId, setAppWindowId, siteArchive, getSiteArchives, templates, toast }) => {
  const s = site.site || {};
  const v = s.values || {};
  //const m = ((site.security || {}).members || {}).names || [];
  const a = site.assessments || [];
  //const u = site.users || [];

  const [users, setUsers] = useState([]);
  const [showAddressModal, setShowAddresModal] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [message, setMessage] = useState('');

  const [formValues, setFormValues] = useState({});
  const [formValid, setFormValid] = useState({});
  const [edited, setEdited] = useState(false);

  const [savingSite, setSavingSite] = useState(false);


  const showAddress = s => {
    const ns = s === true || s === false ? s : !showAddressModal;

    if (ns) resetVals();

    setShowAddresModal(ns);
  }

  const resetVals = () => {
    const defaults = {
      name: v.name,
      address: v.address,
      telephone: v.telephone,
      nocontact: v.nocontact
    }

    setFormValues(defaults);
    setFormValid({});
    setEdited(false);
    setMessage('');
  }

  //Set form value on edit
  const setFormVal = (name, check) => e => {
    const value = typeof e === "object" ? e.target.value : e;

    setFormValues(fv => ({ ...fv, [name]: value }));

    setEdited(true); //Some form value has been edited

    if (check) {
      //[].concat(check).forEach //!Run multiple checks, check for required

      const isValid = check(value);

      setFormValid({ ...formValid, [name]: (isValid === true || isValid) });
    }
  }

  //Check if a field has been set as valid
  const valid = field => formValid[field] && formValid[field] !== true ? formValid[field] : '';

  //Update site data
  const doUpdate = async () => {
    setUpdating(true);
    setMessage('');

    try {
      const doc = { ...formValues, siteid: site.value };
      var ap = await dbFunctions.dbAdminPost({ query: ['sites', 'tgupdatesitedetails'], doc });
    } catch (err) {
      console.log("Error updating site details", err);
      toast(`Error updating site details: ${err}`);
    }

    setUpdating(false);

    if (ap) {
      toast(ap.message);
      if (ap.success) {
        updateSite();
        showAddress(false);
      }
      else {
        setMessage(ap.message);
      }
    }
    else {
      toast(`Error updating site`);
    }

    return ap;
  }

  //Open App at particular Site for Admin login
  const openApp = (siteid, sitename) => () => {

    //console.log("openApp: appWindowId: ", appWindowId);

    if (appWindowId) try {
      appWindowId.postMessage('Close', '*'); //appWindowId.close();
    } catch (err) {

    }

    const url = `${process.env.REACT_APP_APP_URL}?site=${siteid}&sitename=${sitename}`;
    const wid = window.open(url);

    setAppWindowId(wid);
  }

  const openDB = (siteid) => () => {
    const url = `${process.env.REACT_APP_DB_SERVER}/_utils/#database/${siteid}/_all_docs`;
    window.open(url);
  }

  //Lookup user data for Site users
  useEffect(() => {
    //const l = userList.filter(uL=>(site.users || []).includes(uL.username));
    const users = site.userdetails;
    setUsers(users);
  }, [site]);

  //Download site zip file
  const downloadSite = async () => {

    setSavingSite(true);

    const ass = await dbFunctions.dbAdminPost({ query: ['trilogy', 'getSite'], doc: { siteid: site.id }, raw: true });

    const assStream = ass.body.getReader();

    try {
      const stream = await new ReadableStream({
        start(controller) {
          return pump();
          function pump() {
            return assStream.read().then(({ done, value }) => {
              // When no more data needs to be consumed, close the stream
              if (done) {
                controller.close();
                return;
              }
              // Enqueue the next data chunk into our target stream
              controller.enqueue(value);
              return pump();
            });
          }
        }
      })

      const response = await new Response(stream);
      const blob = await response.blob();
      var url = await URL.createObjectURL(blob, { type: 'application/zip' });

    } catch (err) {
      toast(`Failed to save site: ${err.message}`);
    }

    /*if(!(ass && ass.length)) {
      toast('Failed to download site zip');
      return;
    }*/

    const cdate = new Date().toISOString().split('.')[0];

    //console.log("Ass: ", ass);

    let link = document.createElement('a');
    //link.setAttribute('href', URL.createObjectURL( new Blob([ass], {type: 'application/zip'}) ) );
    link.setAttribute('href', url);
    link.setAttribute('download', `FRA_${site.value}-${cdate}.zip`);
    link.click();

    setSavingSite(false);
  }

  //console.log("Site / archive: ", site.id, siteArchive);

  return (
    <tr key={site.id}>
      {/*<td>{s.user}</td>*/}
      <td className="buttonfill">
        <button title="Edit Site Details" className="plain left" onClick={showAddress}>
          <div>{v.name}</div>
        </button>
        {v.nocontact && <div className="small">No contact until: {ukdate(v.nocontact)}</div>}
        <div className="vsmall">{site.value}</div>
      </td>
      <td className="buttonfill" style={{ whiteSpace: 'pre-wrap' }}>
        <button title="Edit Site Details" className="plain left" onClick={showAddress}>
          {v.address}<div style={{ marginTop: '0.5em' }}><FAIcon icon="phone" /> {v.telephone}</div>
        </button>

        <Modal show={showAddressModal} size="lg" onHide={showAddress}>
          <Form>
            <Modal.Header closeButton>
              <Modal.Title>{`Site Details: ${v.name}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {message &&
                <Alert variant='primary'>
                  {message}
                </Alert>}
              <Form.Group>
                <Form.Label>Site Name</Form.Label>
                <Form.Control as="input" value={formValues.name || ''} onChange={setFormVal("name")} autoComplete="new-password" />
                <Form.Text className="text-muted">{valid('name')}</Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Label>Site Address</Form.Label>
                <Form.Control as="textarea" rows="5" value={formValues.address || ''} onChange={setFormVal("address")} autoComplete="new-password" />
                <Form.Text className="text-muted">{valid('address')}</Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Label>Site Contact Number</Form.Label>
                <Form.Control as="input" type="tel" value={formValues.telephone || ''} onChange={setFormVal("telephone")} autoComplete="new-password" />
                <Form.Text className="text-muted">{valid('telephone')}</Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Label>No contact until</Form.Label>
                <Form.Control as="input" type="date" value={formValues.nocontact || ''} onChange={setFormVal("nocontact")} autoComplete="new-password" />
                <Form.Text className="text-muted">(Optional: don't contact users re/ this site until...)</Form.Text>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              {edited
                ? <>
                  <Button variant="success" onClick={doUpdate}>{updating && <Spinner as="span" animation="border" size="sm" />} Save</Button>
                  <Button variant="danger" onClick={showAddress}>Cancel</Button>
                </>
                : <Button onClick={showAddress}>Close</Button>
              }
            </Modal.Footer>
          </Form>
        </Modal>
      </td>
      <td className="buttonfill" style={{ position: 'relative' }}>
        <Sharing siteid={site.id} sitename={v.name} users={users} updateSite={updateSite} updateUsers={updateUsers} userList={userList} dbFunctions={dbFunctions} toast={toast} />
      </td>
      <td className="assT">
        {a.map(a => <Assessment key={a._id} assessment={a} site={site} sitename={v.name} templates={templates} updateSite={updateSite} dbFunctions={dbFunctions} toast={toast} />)}
      </td>
      <td>
        {/*<Button variant="outline-secondary" onClick={()=>{}}><FAIcon icon="trash" /></Button>*/}
        <ButtonGroup>
          {/*<Button title="Open" as="a" variant="outline-secondary" href={`${process.env.REACT_APP_APP_URL}?site=${site.value}&sitename=${v.name}`} target="_blank" rel="noopener noreferrer">
            <FAIcon icon="play-circle" />
            </Button>*/}
          <AddAssessmentModal siteid={site.id} sitename={v.name} templates={templates} users={users} dbFunctions={dbFunctions} updateSite={updateSite} toast={toast} />
          <Button title="Open Site" variant="outline-secondary" onClick={openApp(site.value, v.name)}><FAIcon icon="play-circle" /></Button>
          <SiteArchiveModal siteid={site.id} sitename={v.name} users={users} dbFunctions={dbFunctions} toast={toast} updateSite={updateSite} archives={site.archive} getSiteArchives={getSiteArchives} siteArchive={siteArchive} assessments={a} />
          <Button title="Download Site" variant="outline-secondary" onClick={downloadSite} disabled={savingSite}>{savingSite ? <Spinner as="span" animation="border" size="sm" /> : <FAIcon icon="floppy-o" />}</Button>
          <Button title="Open Database" variant="outline-secondary" onClick={openDB(site.value)}><FAIcon icon="database" /></Button>
          <DeleteSite title="Delete" siteid={site.id} sitename={v.name} users={users} deleteSite={deleteSite} />
        </ButtonGroup>

      </td>
    </tr>
  );
}


const DeleteSite = ({ siteid, sitename, users = [], className, style, deleteSite }) => {

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');
  const [checked, setChecked] = useState([]);
  const [deleting, setDeleting] = useState(false);

  const showModal = s => setShow(s === true || s === false ? s : !show);

  //Add/remove user from checked list
  const switchUser = u => e => {
    setChecked(
      checked.includes(u)
        ? checked.filter(c => c !== u)
        : [].concat(checked, u)
    )
  }

  const doDelete = async () => {
    setDeleting(true);
    setMessage('');

    const doDelete = await deleteSite(checked);

    if (doDelete.success) setShow(false);
    else {
      setMessage(doDelete.message);
    }

    setDeleting(false);
  }

  useEffect(() => { setChecked(users.map(u => u.username).sort()) }, [users]);

  return (
    <ButtonGroup className={className} style={style}>

      <Button onClick={showModal} variant="outline-secondary"><FAIcon icon="trash" />…</Button>

      <Modal show={show} size="lg" onHide={showModal}>
        <Modal.Header>
          <Modal.Title>Delete Site: {sitename}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message && <Alert variant='primary'>
            {message}
          </Alert>}

          <Form>
            <Form.Group>
              <Form.Label>Notify Users of site deletion?</Form.Label>
              {users.map(u => {
                const us = u.username;
                return <Form.Switch key={u.id} id={`dnot_${us}`} checked={checked.includes(us)} onChange={switchUser(us)} label={`${u.actualname || u?.doc?.fullname || ''} [${u.username}]`} />
              })}
            </Form.Group>
          </Form>

        </Modal.Body>
        <Modal.Footer>
          <Button type="button" variant="secondary" onClick={showModal}>Cancel</Button>
          <Button type="button" variant="danger" onClick={doDelete} disabled={deleting}>{deleting ? <Spinner as="span" animation="border" size="sm" /> : <FAIcon icon="trash" />} Delete</Button>
        </Modal.Footer>
      </Modal>
    </ButtonGroup>
  )
}



export default Sites;