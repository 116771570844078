import React, { useState, useEffect, useCallback } from 'react';
//import { StyleSheet, css } from 'aphrodite';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { DisAlert } from '../Components';

import AceEditor from "react-ace";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-github";

import ReactJson from 'react-json-view';


const defaultSearch = `{
  "selector": {
     "type": {
        "$eq": "LicenseSurvey"
     }
  }
}`;

const defaultJs = `
  /*
  Document is passed as an object called 'obj';
  this should be modified and returned
  */

  return {...obj};
`;

const DBEdit = ({ siteList, dbFunctions, toast }) => {
  const [text, setText] = useState(defaultSearch);
  const [searchResults, setSearchResults] = useState([]);

  const [editCode, setEditCode] = useState(defaultJs);
  //const [editOut, setEditOut] = useState([]);

  const [sites, setSites] = useState([]);

  const [displaySites, setDisplaySites] = useState(10);
  const [displayDocs, setDisplayDocs] = useState(3);

  const doSearch = useCallback(async ()=> {
    //console.log("Search: ", text);

    //const s = sites[0].id;

    const sr = await Promise.all(sites.map(s =>
      dbFunctions.dbFetch({ query: [s.id, '_find'], params: { body: JSON.stringify({ ...JSON.parse(text) }) }, method: 'post' })
    ));

    setSearchResults(sr.map(r => (
      r?.docs
    )));
  }, [sites, text, dbFunctions])

  const runEditFunction = obj => {
    if (!obj) return '';

    const fcode = ('const obj=' + JSON.stringify(obj) + '; ' + editCode);

    //console.log("obj, fcode: ", obj, fcode);

    try {
      /*eslint no-new-func: 0*/
      var out = Function(fcode)();
    } catch (err) {
      return {'Error': err.message};
    }

    return out;
    //return {};
  }

  useEffect(() => {
    setSites(displaySites?(siteList || []).slice(0, displaySites):siteList);
    console.log("Update sites");
  }, [siteList, displaySites]);

  useEffect(() => {
    doSearch();
  }, [sites, doSearch])

  return (
    <div className="tab-body">
      <DisAlert>Take great care using these functions</DisAlert>

      {/*<InputGroup>
        <InputGroup.Prepend>
          <FAIcon icon="search" />
        </InputGroup.Prepend>
        <FormControl aria-label="Search" placeholder="Search" value={search} onChange={change} />
        <InputGroup.Append>
          <Button>Search</Button>
        </InputGroup.Append>
      </InputGroup>*/}
      <div>
        <InputGroup className="input-group-no-bottom-radius">
          <InputGroup.Text className="form-control" style={{ fontWeight: 'bold' }}>Database Find and Replace</InputGroup.Text>
          <InputGroup.Text className="input-group-inline-text">Sites:</InputGroup.Text>
          <Form.Control type="Number" value={displaySites} onChange={e => setDisplaySites(e.target.value)} style={{ maxWidth: '5em' }} />
          <InputGroup.Text className="input-group-inline-text">Docs/Site:</InputGroup.Text>
          <Form.Control type="Number" value={displayDocs} onChange={e => setDisplayDocs(e.target.value)} style={{ maxWidth: '5em' }} />
            <Button onClick={doSearch}>Search</Button>
        </InputGroup>

        <div style={{border: '1px solid #ccc'}}>
          <div style={{width: "50%", display: "inline-block", padding: '0 0.75em'}}>
            Mango (JSON) query
          </div>
          <div style={{width: "50%", display: "inline-block", padding: '0 0.75em'}}>
            Javascript modify function
          </div>
        </div>

        <AceEditor
          mode="json"
          theme="github"
          value={text}
          onChange={setText}
          name="searchId"
          style={{
            height: "15em",
            width: "50%",
            display: "inline-block",
            border: "1px solid #ced4da",
            borderTop: 0,
            marginBottom: '1em'
          }}
          editorProps={{ $blockScrolling: true }}
        /*setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: true
        }}*/
        />

        <AceEditor
          mode="javascript"
          theme="github"
          value={editCode}
          onChange={setEditCode}
          name="searchId"
          esversion={9}
          style={{
            height: "15em",
            width: "50%",
            display: "inline-block",
            border: "1px solid #ced4da",
            borderTop: 0,
            marginBottom: '1em'
          }}
          editorProps={{ $blockScrolling: true }}
          onLoad={ editor => {
            editor.session.$worker.send('changeOptions', [{ esversion: 9 }])
          }}
        /*setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: true
        }}*/
        />

      </div>

      <div>
          {sites.map((s, i) => {
            const sr = searchResults[i];

            const doccount = sr?.length || '-';
            const docs = sr || [];

            const docsview = docs.slice(0, displayDocs);

            return (
              <div key={s.id}>
                  <InputGroup className="input-group-no-bottom-radius">
                    <InputGroup.Text className="form-control" style={{ fontWeight: 'bold' }}><span title={s.site?.values?.address}>{s.site?.values?.name}</span></InputGroup.Text>
                    <InputGroup.Text className="form-control" style={{ fontWeight: 'bold' }}>{s.id}</InputGroup.Text>

                    <InputGroup.Text className="input-group-inline-text">Docs:</InputGroup.Text>
                    <InputGroup.Text className="form-control" style={{ maxWidth: '5em' }}>{doccount}</InputGroup.Text>
                  </InputGroup>

                  {docsview.map(d => (
                    <div key={d?._id}>
                      <div style={{ height: '10em', overflow: 'auto',width: "50%", display: "inline-block", border: "1px solid #ced4da", borderTop: 0 }}>
                        <ReactJson src={d} name={null} collapsed={1} enableClipboard={false} displayDataTypes={false} style={{ display: "inlineBlock", width: "50%" }} />
                      </div>
                      <div style={{ height: '10em', overflow: 'auto', width: "50%", display: "inline-block", border: "1px solid #ced4da", borderTop: 0 }}>
                        <ReactJson src={runEditFunction(d)} name={null} collapsed={1} enableClipboard={false} displayDataTypes={false} style={{ display: "inlineBlock", width: "50%" }} />
                      </div>
                    </div>
                  ))}
              </div>
              )
            })}
      </div>

    </div>
  )
}

export default DBEdit;