import React, { useState } from 'react';

import { Modal, Form, Button, ButtonGroup, InputGroup, Spinner, Dropdown } from 'react-bootstrap';

import { FAIcon } from '../Components';

/**
 * Popup to add new Assessment
 * @param {*} templates 
 */
 const AddAssessmentModal = ({ siteid, sitename, templates, users, dbFunctions, updateSite, toast }) => {

    const [show, setShow] = useState(false);
  
    const [formValues, setFormValues] = useState({});
    const [formValid, setFormValid] = useState({});
    //const [siteid, setSiteId] = useState(false);
  
    const [addingAssessment, setAddingAssessment] = useState(false);
    //const [message, setMessage] = useState('');
  
    const showModal = s => {
      const ns = s === true || s === false ? s : !show;
      clear();
  
      //if (ns) setSiteId(cuid());
  
      setShow(ns);
    }

    /**
     * Get best available default user id (email) from user list
     * @returns 
     */
    const getBestEmail = () => {
      if(!users.length) return '';

      if(users.length===1) return users[0].username;

      //Try to find a non-Trilogy email address
      return (users.find(u=>!['firesmart.co.uk','trilogygroup.com','healthandsafetysmart.co.uk'].includes(u.username.split('@').pop())) || users[0]).username;
    }
  
    const clear = () => {
      const defaults = {};
  
      defaults.licencelength = 1;
      //if(users.length) defaults.user = users[0].username;
      defaults.user = getBestEmail();
  
      //Default template(?)
      const dt = (templates.find(t => t.default) || {})._id;
      if (dt) defaults.template = dt;
  
      setFormValues(defaults);
      setFormValid({});
    }
  
    //Set form value on edit
    const setFormVal = (name, check) => e => {
      const value = typeof e === "object" ? e.target.value : e;
  
      setFormValues({ ...formValues, [name]: value });
  
      if (check) {
        //[].concat(check).forEach //!Run multiple checks, check for required
  
        const isValid = check(value);
  
        setFormValid({ ...formValid, [name]: (isValid === true || isValid) });
      }
    }
  
    //Check if a field has ben set as valid
    const valid = field => formValid[field] && formValid[field] !== true ? formValid[field] : '';
  
    const addAssessment = async () => {
  
      setAddingAssessment(true);
  
      try {
        const doc = { ...formValues, siteid };
        var ap = await dbFunctions.dbAdminPost({ query: ['sites', 'tgaddassessment'], doc });
      } catch (err) {
        toast(`Error adding assessment: ${err}`);
      }
  
      setAddingAssessment(false);
  
      //getDbs();
      if (ap) {
        if (ap.success) {
          toast(`Assessment added!`)
          updateSite();
          setShow(false);
        }
        else {
          toast(`Error adding assessment: ${ap.message}`);
        }
      }
      else {
        toast(`Error adding assessment`);
      }
  
      return ap;
    }
  
    //const enterOK = e => e && e.key === 'Enter' && tryAddSite();
  
    //const sv = f => e => f(e.target.value);
  
    //Format template for display
    const templateformat = (template) => (
      <div>
        <div>{template.name}</div>
        <div className="small">{template.customer ? `[${template.customer}] ` : ''}[{new Date(template.when).toString().substring(0, 24)}]</div>
      </div>
    )

    return (
  
      <ButtonGroup>
  
        <Button title="Add Assessment" onClick={showModal} variant="outline-secondary"><FAIcon icon="plus-circle"/></Button>
  
        <Modal show={show} size="lg" onHide={showModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add Assessment to Site "{sitename}"</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Form>
              <Form.Group>
                <Form.Label>Assessment Template</Form.Label>
                <Dropdown>
                  <Dropdown.Toggle variant="outline-secondary">
                    {formValues && formValues.template ? templateformat(templates.find(t => t._id === formValues.template) || {}) : 'Template'}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {templates.sort((a, b) => a.when > b.when ? -1 : 1).map(t =>
                      <Dropdown.Item onClick={() => setFormVal("template")(t._id)} key={t._id}>{templateformat(t)}</Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
              <Form.Group>
                <Form.Label>Licence User</Form.Label>
                {users?.length ?
                <Dropdown>
                  <Dropdown.Toggle variant="outline-secondary" title="User">
                    {formValues.user ? `${users.find(u=>u.username===formValues.user)?.actualname} <${formValues?.user}>` : "User"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {
                      users.map(u =>
                        <Dropdown.Item key={u.username} onClick={() => setFormVal("user")(u.username)} >{u.actualname} &lt;{u.username}&gt;</Dropdown.Item>
                      )
                    }
                  </Dropdown.Menu>
                </Dropdown>
                : <div>[No Users added to Site]</div>
                }
              </Form.Group>
              <Form.Group>
                <Form.Label>Licence Duration (Years)</Form.Label>
                <InputGroup>
                  <Form.Control as="input" type="number" value={formValues.licencelength || ''} onChange={setFormVal("licencelength")} autoComplete="new-password" />
                  <Button variant="outline-secondary" active={Number(formValues.licencelength) === 1} onClick={() => setFormVal('licencelength')(1)} >1 Year</Button>
                  <Button variant="outline-secondary" active={Number(formValues.licencelength) === 3} onClick={() => setFormVal('licencelength')(3)} >3 Years</Button>
                </InputGroup>
                <Form.Text className="text-muted">{valid('licencelength')}</Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Label>Or: Custom expiry date</Form.Label>
                <Form.Control as="input" type="date" value={formValues.expiry || ''} onChange={setFormVal("expiry")} autoComplete="new-password" />
                <Form.Text className="text-muted">{valid('expiry')}</Form.Text>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" variant="primary" onClick={addAssessment}>{addingAssessment && <Spinner as="span" animation="border" size="sm" />} Add</Button>
          </Modal.Footer>
        </Modal>
      </ButtonGroup>
  
    )
  }

  export { AddAssessmentModal }