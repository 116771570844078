import ISOdate from './ISOdate';

const downloadCSV = (report, reportname) => async () => {

    let csvContent = "data:text/csv;charset=utf-8," 
    + report.filter(r=>r).map(e => `"${e.join('","')}"`).join("\n");

    const apptitle = process.env.REACT_APP_MAINTITLE;

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${reportname}_${apptitle}_${ISOdate()}.csv`);
    document.body.appendChild(link); // Required for FF

    link.click();
}

export default downloadCSV;