

const FAIcon = ({ icon, children, overlayIcon, count, title, className }) => 
    <span title={title}>
    {overlayIcon
    ?<span className="fa-stack">
        <span className={`fa fa-${icon || children || 'square-o'} fa-stack-1x ${className || ''}`} />
        <span className={`fa fa-${overlayIcon} fa-stack-1x fa-inverse`} />
    </span>
    :<span className={`fa fa-${icon || children || 'square-o'} ${className || ''}`} />}
    {count}
    </span>

export default FAIcon;