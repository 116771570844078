import React from 'react'; //{ useState, useEffect }

//import MDEditor from '../Editor';


const SettingsIntro = ({ intro }) => {

  /*const onChange = val => {
    console.log("val: ", val);
  }*/

  return (
    <div>
      <div className="tab-body">
        App Intro
      </div>

      <p>Under contruction</p>

      {/* <MDEditor onChange={onChange} /> */}
    </div>
  )
}


export default SettingsIntro;