import React, { useState, useEffect } from 'react';
//import { StyleSheet, css } from 'aphrodite';
import { Button, Table, InputGroup, Spinner } from 'react-bootstrap';
import { Searcher } from '../Components';

import orderReport from './orderReport';
import downloadCSV from './downloadCSV';

//import naturalSort from 'javascript-natural-sort';

/*
const ansort = (a,b) => {
  const va=a.trim().split(' ').shift();
  const vb=b.trim().split(' ').shift();

  const out = (isNaN(va)?va:Number(va)) < (isNaN(vb)?vb:Number(vb)) ? -1 : 1;

  console.log("va, vb: ", va, vb, out);

  return out;
}
*/

const Usage = ({ dbFunctions, toast }) => {

  const [updating, setUpdating] = useState(false);
  const [report, setReport] = useState([]);

  const [reportHeader, setReportHeader] = useState([]);
  const [reportObj, setReportObj] = useState([]);
  const [reportSearch, setReportSearch] = useState([]);
  const [orderBy, setOrderBy] = useState({});

  const reportToObj = (report,headers) => report.map(r=>Object.assign(...r.map((c,ci)=>({ [headers[ci]]:c }) )))

  useEffect(()=>{
    if(report.length) {
      const headers = report[0];
      const body = report.slice(1);
      //setReportOrdered(report.slice(1));

      const ro = reportToObj(body, headers);

      setReportHeader(report[0]);
      setReportObj(ro);
      setReportSearch(ro);

    } else {
      setReportHeader([]);
      setReportSearch([]);
      setReportObj([]);
    }

    setOrderBy({});

  }, [report]);

  const getReport = async () => {
    setUpdating(true);

    console.log("Updating usage report...");

    const report = await dbFunctions.dbAdminPost( {query: ['trilogy', 'tgusagereport'], doc: { }} );

    console.log("data: ", report);

    if(report.success) {
      setReport(report.data);
    }
    else {
      toast(report.message || 'Report updated');
    }

    setUpdating(false);
  }

  const setOrder = fieldno => () => {
    if(fieldno===false) setOrderBy({});
    else {
      const direction = orderBy?.fieldno===fieldno && orderBy?.direction ? -(orderBy.direction) : 1;

      setOrderBy({fieldno, direction});
    }
  }


  return (
    <>
      <br />
      <InputGroup>
          <Button variant="success" onClick={getReport}>{updating && <Spinner as="span" animation="border" size="sm" />} {report.length>0?'Update':'Get'} Report</Button>
          {report.length>0 &&
            <>
              <Button variant="success" onClick={()=>setReport([])}>Clear</Button>
              <Button variant="success" onClick={downloadCSV(report, "UsageReport")}>Download CSV</Button>
            </>
          }
      </InputGroup>

      <Table striped bordered hover>
        <thead className="stickyHead">
          {reportObj?.length>0 && <tr>
            <th colSpan={'100%'} style={{padding: 0}}>
              <Searcher
                keys={reportHeader}
                values={reportObj}
                onChange={setReportSearch}
              >
              </Searcher>
            </th>
          </tr>}
          <tr>
            {reportHeader.map((r,k)=>
                <th key={k}>
                  <button type="button" title={`Order by ${r}`} className="plain left" onClick={setOrder(k)} onDoubleClick={setOrder(false)} >
                    {r} 
                    {orderBy?.fieldno===k?` ${orderBy.direction===1?'▼':'▲'}`:''}
                  </button>
                </th>
              )
            }
          </tr>
        </thead>
        <tbody>
          {orderReport(reportSearch, orderBy).map((r,k)=>
              <tr key={k}>{Object.values(r)?.map((c,k)=>
                reportHeader[k]?<td key={k}>{c}</td>:null
              )}</tr>
            )
          }
        </tbody>
      </Table>
    </>
  )

}

export default Usage;